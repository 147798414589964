import {
  ProcessResultManualStatusEnumAML,
  WorkflowStatusKeysTypes,
} from 'entities/entity'

export enum CommentsTypes {
  WORKFLOW = 'COMMENT_WORKFLOW',
  AML = 'COMMENT_AML',
  KYC = 'COMMENT_KYC',
  PROFILE = 'COMMENT_PROFILE',
  AMLRESULT = 'COMMENT_AMLRESULT',
}

export enum FunctionNameTypes {
  CREATEINDIVIDUAL = 'CreateIndividual',
  UPDATEINDIVIDUAL = 'UpdateIndividual',
  UPDATEPROFILE = 'UpdateProfile',
  OVERRIDEWORKFLOWEXECUTIONRESULT = 'OverrideWorkflowExecutionResult',
  UPDATEAML = 'UpdateAML',
}

export type SupplementaryData = {
  resultsUpdated: {
    changeDescription: string
    newStatus: ProcessResultManualStatusEnumAML
    objectId: string
    objectType: string
  }[]

  fieldsUpdated: {
    changeDescription: string
    objectId: string
    objectType: string
  }[]
}

export const successVariantsForAML = [
  ProcessResultManualStatusEnumAML.FALSE_POSITIVE,
  ProcessResultManualStatusEnumAML.UNKNOWN_ACCEPT,
  ProcessResultManualStatusEnumAML.TRUE_POSITIVE_ACCEPT,
]

export const failedVariantsForAML = [
  ProcessResultManualStatusEnumAML.UNKNOWN_REJECT,
  ProcessResultManualStatusEnumAML.TRUE_POSITIVE_REJECT,
]

export const warningVariantsForAML = [
  ProcessResultManualStatusEnumAML.UNKNOWN,
  ProcessResultManualStatusEnumAML.TRUE_POSITIVE,
]

export const successVariantsForWorkflow = [
  WorkflowStatusKeysTypes.MANUALLY_PASSED,
  WorkflowStatusKeysTypes.PASSED,
]

export const failedVariantsForWorkflow = [
  WorkflowStatusKeysTypes.MANUALLY_FAILED,
  WorkflowStatusKeysTypes.FAILED,
]

export const warningVariantsForWorkflow = [
  WorkflowStatusKeysTypes.NEEDS_ATTENTION,
]

export const alertVariantsForWorkflow = [WorkflowStatusKeysTypes.UNCHECKED]
