import React, { useMemo } from 'react'

import { individualAmlScreeningSummaryQa } from 'features/individual-aml-screening/qa/individul-aml-screening.qa'

import { ProcessResultManualStatusEnumAML } from 'entities/entity'

import { useI18n } from 'shared/i18n'

import { INDIVIDUAL_AML_SCREENING_KEY } from '../../individual-aml-screening.key'
import { individualAmlScreeningEn } from '../../locale/individual-aml-screening.en'
import { useAmlPRORowData } from '../../state/individual-aml-screening.state/individual-aml-screening.state'

type Props = {
  entityId: string
  workflowExecutionId?: string
  historyPage?: boolean
}

export function IndividualAmlScreeningSummary({
  entityId,
  workflowExecutionId,
  historyPage = false,
}: Props) {
  const t = useI18n(INDIVIDUAL_AML_SCREENING_KEY, {
    keys: individualAmlScreeningEn,
  })

  const { rows } = useAmlPRORowData({
    entityId,
    workflowExecutionId,
    isNonValid: historyPage,
  })

  const summary = useMemo(() => {
    const potentialMatch = rows.filter(row => !row.matchStatus).length
    const unknown = rows.filter(row =>
      row.matchStatus?.includes(ProcessResultManualStatusEnumAML.UNKNOWN),
    ).length
    const truePositive = rows.filter(row =>
      row.matchStatus?.includes(ProcessResultManualStatusEnumAML.TRUE_POSITIVE),
    ).length
    const falsePositive = rows.filter(row =>
      row.matchStatus?.includes(
        ProcessResultManualStatusEnumAML.FALSE_POSITIVE,
      ),
    ).length

    return {
      potentialMatch,
      unknown,
      truePositive,
      falsePositive,
    }
  }, [rows])

  return (
    <>
      {!historyPage && (
        <div
          className="flex flex-col gap-2"
          data-qa={individualAmlScreeningSummaryQa.container}
        >
          <div
            className="font-semibold  text-md text-tertiary-grey-800 leading-[24px] text-left"
            data-qa={individualAmlScreeningSummaryQa.amlTitle}
          >
            {t('amlSearch')}
          </div>
          <div className="flex gap-y-2 flex-wrap">
            <div
              className="grow min-w-[150px] basis-1/4"
              data-qa={individualAmlScreeningSummaryQa.name}
            >
              <div className="text-sm text-tertiary-grey-800 font-medium">
                {t('name')}
              </div>
              <div className="text-sm text-tertiary-grey-700 font-normal">
                Jane Citizen
              </div>
            </div>
            <div
              className="grow min-w-[150px] basis-1/4"
              data-qa={individualAmlScreeningSummaryQa.dob}
            >
              <div className="text-sm text-tertiary-grey-800 font-medium">
                {t('yearOfBirth')}
              </div>
              <div className="text-sm text-tertiary-grey-700 font-normal">
                1990
              </div>
            </div>
            <div
              className="grow min-w-[150px] basis-1/4"
              data-qa={individualAmlScreeningSummaryQa.country}
            >
              <div className="text-sm text-tertiary-grey-800 font-medium">
                {t('residence')}
              </div>
              <div className="text-sm text-tertiary-grey-700 font-normal">
                AU
              </div>
            </div>
            <div
              className="grow min-w-[150px] basis-1/4"
              data-qa={individualAmlScreeningSummaryQa.fuzziness}
            >
              <div className="text-sm text-tertiary-grey-800 font-medium">
                {t('fuzziness')}
              </div>
              <div className="text-sm text-tertiary-grey-700 font-normal">
                10%
              </div>
            </div>
          </div>
        </div>
      )}
      {rows.length > 0 && (
        <div className="flex flex-col gap-2">
          <div
            className="font-semibold text-md text-tertiary-grey-800 leading-[24px] text-left"
            data-qa={individualAmlScreeningSummaryQa.matchSummary}
          >
            {t('matchSummary')}
          </div>
          <div className="flex flex-wrap">
            <div className="grow min-w-[150px] basis-1/4">
              <div
                className="text-sm text-tertiary-grey-800 font-medium"
                data-qa={individualAmlScreeningSummaryQa.potentialMatch}
              >
                {t('potentialMatch')}
              </div>
              <div className="text-xl text-tertiary-grey-700 font-semibold">
                {summary.potentialMatch}
              </div>
            </div>
            <div className="grow min-w-[150px] basis-1/4">
              <div
                className="text-sm text-tertiary-grey-800 font-medium"
                data-qa={individualAmlScreeningSummaryQa.unknown}
              >
                {t('unknown')}
              </div>
              <div className="text-xl text-tertiary-grey-700 font-semibold">
                {summary.unknown}
              </div>
            </div>
            <div className="grow min-w-[150px] basis-1/4">
              <div
                className="text-sm text-tertiary-grey-800 font-medium"
                data-qa={individualAmlScreeningSummaryQa.truePositive}
              >
                {t('truePositive')}
              </div>
              <div className="text-xl text-tertiary-grey-700 font-semibold">
                {summary.truePositive}
              </div>
            </div>
            <div className="grow min-w-[150px] basis-1/4">
              <div
                className="text-sm text-tertiary-grey-800 font-medium"
                data-qa={individualAmlScreeningSummaryQa.falsePositive}
              >
                {t('falsePositive')}
              </div>
              <div className="text-xl text-tertiary-grey-700 font-semibold">
                {summary.falsePositive}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
