import React, { useMemo, useState } from 'react'

import {
  DOCUMENT_F2_KEY,
  documentF2En,
} from 'entities/document-f2/locale/document-f2.en'
import { documentIdTypesOption } from 'entities/document-f2/model/document-id-types.model'
import { DocumentInformation } from 'entities/entity'

import { FrankieUtilityImg } from 'shared/FrankieImg'
import { DateFormatTypes, formatDate } from 'shared/date-time'
import { DocumentImg } from 'shared/documentImg'
import { convertDataURItoBlob, makeDataURL } from 'shared/file'
import { Show } from 'shared/hoc'
import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { PdfPreview } from '../pdf-preview/pdf-preview'

type Props = {
  document: DocumentInformation
  className?: string
  extraData: { label: string; value?: string }[]
  trackingEvents?: {
    gallery: TrackingEventsTypes[]
    rotate: TrackingEventsTypes
    zoom: TrackingEventsTypes
    show: TrackingEventsTypes
  }
}
export function DocumentViewF2R2({
  document,
  extraData,
  className = '',
  trackingEvents,
}: Props) {
  const [currentIndex] = useState(0)
  const [createOverlay] = useOverlay()
  const t = useI18n(DOCUMENT_F2_KEY, { keys: documentF2En })

  const { url, isImage } = useMemo(() => {
    const attachment = document.attachments?.[currentIndex]
    const multipleAttachments = (document.attachments?.length ?? 0) > 1
    const mimeType = attachment?.mimeType
    const fileUrl =
      attachment?.data && 'base64' in attachment.data
        ? attachment.data.base64
        : ''

    if (!fileUrl || !mimeType) {
      // Safety check
      return { url: '', isImage: false, isVideo: false }
    }
    const temp_url = makeDataURL(mimeType, fileUrl)
    const blob = convertDataURItoBlob(temp_url)
    const url = window.URL.createObjectURL(blob)
    const isImage = mimeType.split('/').includes('image')
    return { url, isImage, multipleAttachments }
  }, [currentIndex, document.attachments])

  if (!url) return null

  const handleClick = () => {
    if (trackingEvents?.show) trackingManager.track(trackingEvents.show)

    const documentLabelTKey = documentIdTypesOption.find(
      ({ value }) => value === document.type,
    )?.tKey

    const documentUploadedBy = Math.random() > 0.5 ? 'Ryan Gosling' : ''

    createOverlay(
      <div className="flex flex-wrap max-w-[850px] gap-x-6 gap-y-4 min-h-[450px]">
        <div className="text-xl basis-full font-semibold">
          {documentLabelTKey ? t(documentLabelTKey) : document.type}
        </div>
        <div className="flex flex-col gap-3">
          <div className="text-tertiary-grey-500 text-sm leading-5">
            {t('uploadedAtVia', {
              date: formatDate(
                document.createdAt ?? new Date().toUTCString(),
                DateFormatTypes.DateNumbersSlash,
              ),
              time: formatDate(
                document.createdAt ?? new Date().toUTCString(),
                DateFormatTypes.Time24HoursWithSeconds,
              ),
              via: documentUploadedBy
                ? t('by', { name: documentUploadedBy })
                : t('directCapture'),
            })}
          </div>

          <Show>
            <Show.When isTrue={isImage}>
              <FrankieUtilityImg
                gallery={[url]}
                className="!w-[610px] !h-[360px]"
                trackingEvents={trackingEvents}
              />
            </Show.When>
            <Show.Else>
              <div
                className="!w-[610px] !h-[460px] rounded-md max-h-96 overflow-y-auto border border-tertiary-grey-200"
                data-hj-suppress
                title="uploaded-file"
              >
                <PdfPreview
                  url={url}
                  pageProps={{
                    renderAnnotationLayer: false,
                    renderTextLayer: false,
                    width: 592,
                  }}
                />
              </div>
            </Show.Else>
          </Show>
          {/* <div className="flex flex-col gap-1">
            <div className="font-medium text-sm leading-5">Older uploads</div>
            <div className="flex gap-1">
              <div className="w-16 h-12 border rounded-sm" />
              <div className="w-16 h-12 border rounded-sm" />
              <div className="w-16 h-12 border rounded-sm" />
            </div>
          </div> */}
        </div>
        <div className="min-w-[200px] max-w-[200px] max-h-[495px] overflow-y-auto">
          {extraData.map(({ label, value }) => (
            <div key={label} className="mt-3">
              <div className="font-medium text-tertiary-grey-800">{label}</div>
              <div
                data-hj-suppress
                className="font-normal  text-tertiary-grey-700"
              >
                {value ?? '-'}
              </div>
            </div>
          ))}
        </div>
      </div>,
      {
        closeButtonClassName: '!top-6 !right-6',
        className: '!p-6',
      },
    )
  }

  return (
    <DocumentImg
      className={className}
      gallery={[url]}
      isImage={[isImage]}
      trackingEvents={trackingEvents?.gallery}
      thumbnailTexts={{
        front: t('front'),
        photoPage: t('photoPage'),
        back: t('back'),
      }}
      handleExpand={handleClick}
    />
  )
}
