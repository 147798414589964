import { useMutation, useQueryClient } from '@tanstack/react-query'

import { NotificationSettingsConfigKey } from 'entities/user'

import { replaceAll } from 'shared/array'

import { notificationsManagementApi } from '../../api/notifications-management.api'
import {
  INotificationSetting,
  INotificationSettingsConfig,
} from '../../model/notifications-management.model'

export const useChangeNotificationSettingsMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({
      newSettings,
    }: {
      newSettings: INotificationSetting[]
    }) => {
      const oldConfig = queryClient.getQueryData<INotificationSettingsConfig>(
        NotificationSettingsConfigKey,
      )
      if (oldConfig) {
        queryClient.setQueryData<INotificationSettingsConfig>(
          NotificationSettingsConfigKey,
          {
            ...oldConfig,
            notificationSettings: replaceAll(
              oldConfig.notificationSettings,
              newSettings,
              setting => `${setting.type}${setting.channel}`,
            ),
          },
        )
      }
      await notificationsManagementApi.changeNotificationSettings(newSettings)
    },
    /**
     * Rollback query changes
     * Unhandled issue: when there are more than one async requests, they can
     * fail in any order and second error can override first error changes
     */
    onError: (_, { newSettings }) => {
      const oldConfig = queryClient.getQueryData<INotificationSettingsConfig>(
        NotificationSettingsConfigKey,
      )
      if (oldConfig) {
        queryClient.setQueryData<INotificationSettingsConfig>(
          NotificationSettingsConfigKey,
          {
            ...oldConfig,
            notificationSettings: replaceAll(
              oldConfig.notificationSettings,
              newSettings.map<INotificationSetting>(setting => ({
                ...setting,
                value: !setting.value,
              })),
              setting => `${setting.type}${setting.channel}`,
            ),
          },
        )
      }
    },
  })
}
