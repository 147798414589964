export const organisationSettingsEn = {
  defaultCountryForm: {
    title: 'Country',
    description:
      'This will set the default value for all country related selection fields (e.g. address) in this account.',
    cta: {
      submit: 'Save country',
    },
  },
  integration: {
    title: 'Integration',
    slack: 'Slack',
    description: 'Get notifications and use the @frankieone bot',
    loading: 'Connecting to Slack',
    cta: {
      submit: 'Save country',
      connect: 'Connect',
      disconnect: 'Disconnect',
    },
  },
}
