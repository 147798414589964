/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import React, { useMemo } from 'react'

import classNames from 'classnames'
import { useLocation, useNavigate } from 'react-router-dom'

import { FrankieButton, FrankieTooltip } from 'frankify/src'

import { COMMENT_KEY } from 'entities/comments/comments.key'
import { commentsEn } from 'entities/comments/locale/comments.en'
import { commentToolTipQa } from 'entities/comments/qa/comments.qa'
import { useApplicantRoute } from 'entities/routing'

import { useI18n } from 'shared/i18n'

import { CommentsTypes } from '../../model/comments.model'
import {
  useCommentData,
  useUpdateCommentBoxVisibility,
} from '../../states/comments-states/comments.state'
import { CommentBox } from '../comment-box/comment-box'

type CommentToolTipProps = {
  className?: string
  logoClassName?: string
  commentType?: CommentsTypes
  entityId: string
  global?: boolean
  processId?: string
}

// eslint-disable-next-line complexity
export function CommentToolTip({
  className = '',
  logoClassName = '',
  commentType = CommentsTypes.WORKFLOW,
  entityId,
  global = false,
  processId,
}: CommentToolTipProps) {
  const navigate = useNavigate()
  const { generateRoute } = useApplicantRoute()

  const profileRoute = generateRoute({
    routeKey: 'individualProfileInsight',
    overrideParams: { entityId },
  })
  const location = useLocation()
  const currentLocation = location.pathname
  const isProfileTab = currentLocation === profileRoute

  const updateCommentBoxVisibility = useUpdateCommentBoxVisibility({
    entityId,
    isProfileTab,
  })
  const t = useI18n([COMMENT_KEY], { keys: commentsEn })

  const { data } = useCommentData({ entityId, commentType, processId })
  const { data: amlData } = useCommentData({
    entityId,
    commentType: CommentsTypes.AML,
    processId,
  })

  const isGlobalBtnDisable = useMemo(
    () => !data?.length && !amlData?.length && global && !isProfileTab,
    [amlData?.length, data?.length, global, isProfileTab],
  )

  if (!global && !data?.length) return null

  return (
    <div className={`${className}`} data-qa={commentToolTipQa.container}>
      <FrankieTooltip
        className={`${
          global
            ? ''
            : '!max-w-[252px] !bg-tertiary-grey-200 after:!bg-tertiary-grey-200 !p-4'
        }`}
        body={
          global ? (
            <div className="tertiary-grey-400 text-xs leading-4">
              {isGlobalBtnDisable ? t('noCommentText') : t('globalIconTitle')}
            </div>
          ) : (
            <CommentBox
              entityId={entityId}
              processId={processId}
              commentType={commentType}
            />
          )
        }
        position={global ? 'bottom' : 'left'}
      >
        <FrankieButton
          noStyles
          singleIcon={{
            name: 'mdiCommentTextOutline',
            className: classNames(
              {
                'text-tertiary-grey-400': isGlobalBtnDisable,
                'text-tertiary-grey-700':
                  !global || data?.length || isProfileTab || amlData?.length,
              },
              'z-20',
              logoClassName,
            ),
            size: 'xs',
          }}
          size="sm"
          disabled={isGlobalBtnDisable}
          onClick={e => {
            if (isProfileTab && global) {
              navigate(
                generateRoute({
                  routeKey: 'applicantProfileWorkflowEvent',
                  overrideParams: { entityId },
                }),
              )
            }
            e.stopPropagation()
            updateCommentBoxVisibility({
              payload: !global
                ? commentType
                : [CommentsTypes.AML, CommentsTypes.WORKFLOW],
              overrideState: isProfileTab ? global : undefined,
            })
          }}
          testId={{ button: commentToolTipQa.logo }}
        />
      </FrankieTooltip>
    </div>
  )
}
