import { useMutation, useQueryClient } from '@tanstack/react-query'

import { NotificationSettingsConfigKey } from 'entities/user'

import { useIntegrationQuery } from './slack-integration.query'
import { organisationSettingsApi } from '../../api/organisation-settings.api'

export const useSlackDisintegrationMutation = () => {
  const { refetch } = useIntegrationQuery()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async () => {
      const { data } = await organisationSettingsApi.deleteIntegrationSettings()
      return data
    },

    onSuccess: () => {
      void queryClient.invalidateQueries(NotificationSettingsConfigKey)
      void refetch()
    },
  })
}
