import { useMutation } from '@tanstack/react-query'

import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { entityApi } from '../../api/entity.api'
import { EntityID } from '../../model/entity.model'
import {
  useEntityDataQuery,
  useUpdateProfileData,
} from '../../state/entity-data/entity-data.query'

type Props = {
  assignee: string
  entityIds?: string[]
}

const mutationKey = 'assignEntity'

export const useAssignEntityMutation = (entityId?: EntityID) => {
  const { data } = useEntityDataQuery(entityId)
  const { updateProfileData } = useUpdateProfileData(entityId)

  const { mutate, isLoading } = useMutation({
    mutationKey: [mutationKey, entityId],
    mutationFn: async (props: Props) => {
      if (!entityId) {
        throw new Error('Entity ID is required')
      }
      const { data } = await entityApi.assignEntity(entityId, props)
      return data
    },
    onSuccess: ({ meta, data: serviceProfiles }) => {
      updateProfileData(serviceProfiles.map(i => i.serviceProfile))

      trackingManager.track(
        data?.individual?.entityType === 'INDIVIDUAL'
          ? TrackingEventsTypes.UserAssignIndividual
          : TrackingEventsTypes.UserAssignOrganization,
      )
    },
  })
  return { data, mutate, isLoading }
}
