import React, { useState } from 'react'

import classNames from 'classnames'
import { Control, FieldPath, UseFormWatch } from 'react-hook-form'

import { FrankieButton, FrankiePopover } from 'frankify/src'

import { I18nKeys, useI18n } from 'shared/i18n'

import { INDIVIDUAL_AML_SCREENING_KEY } from '../../../../individual-aml-screening.key'
import { individualAmlScreeningEn } from '../../../../locale/individual-aml-screening.en'
import { AmlFilters } from '../../../../model/individual-aml-screening/individual-aml-screening.model'
import { useAmlFiltersOptions } from '../../../../state/individual-aml-screening.state/individual-aml-screening.state'
import { IndividualAmlFormComponent } from '../individual-aml-form-component'

export function FilterComponent({
  amlKey,
  control,
  watch,
  name,
  show,
  testId = {
    amlFilter: '',
    amlContainer: '',
    btn: '',
  },
}: {
  control: Control<AmlFilters, unknown>
  watch: UseFormWatch<AmlFilters>
  name: FieldPath<AmlFilters>
  amlKey: keyof AmlFilters
  show: boolean
  testId?: {
    amlFilter: string
    amlContainer: string
    btn: string
  }
}) {
  const t = useI18n([INDIVIDUAL_AML_SCREENING_KEY], {
    keys: individualAmlScreeningEn,
  })

  const value = watch(name)

  const [popoverStates, setPopoverStates] = useState({
    amlMatch: false,
    matchStatus: false,
    issues: false,
    workflow: false,
  })

  const togglePopover = (popoverName: keyof typeof popoverStates) => {
    setPopoverStates(prevStates => ({
      ...prevStates,
      [popoverName]: !prevStates[popoverName],
    }))
  }
  const {
    amlIssuesOptions,
    amlMatchOptions,
    matchStatusOptions,
    workflowOptions,
  } = useAmlFiltersOptions()

  const optionsMapper = {
    amlMatch: amlMatchOptions,
    matchStatus: matchStatusOptions,
    issues: amlIssuesOptions,
    workflow: workflowOptions,
  }

  if (!show) return null

  return (
    <FrankiePopover
      open={popoverStates[amlKey]}
      onOpenChange={() => togglePopover(amlKey)}
      popoverRest={{
        placement: 'bottom-start',
      }}
      trigger={
        <FrankieButton
          size="xs"
          className={classNames(
            'cursor-pointer min-w-[128px] justify-between',
            {
              'bg-primary-50 hover:!bg-primary-50 border-transparent':
                popoverStates[amlKey] || value.length,
            },
          )}
          intent="darkOutline"
          onClick={() => togglePopover(amlKey)}
          endIcon={{
            className: 'pointer-events-none',
            size: 'xs',
            name: popoverStates[amlKey] ? 'mdiChevronUp' : 'mdiChevronDown',
          }}
          testId={{ button: testId.btn }}
        >
          {t(`amlFiltersLabel.${amlKey}`, { count: value.length || undefined })}
        </FrankieButton>
      }
    >
      <div
        className={`bg-mono-white shadow-md min-w-[180px] p-3 rounded-sm ${
          amlKey === 'workflow' ? 'max-h-[200px] overflow-scroll' : ''
        }`}
        data-qa={testId.amlContainer}
      >
        <IndividualAmlFormComponent
          control={control}
          name={name}
          options={[
            optionsMapper[amlKey].map(option => ({
              label: t(
                option.label as I18nKeys<typeof individualAmlScreeningEn>,
              ),
              value: option.value,
            })),
          ]}
          testId={{ amlFilter: testId.amlFilter }}
        />
      </div>
    </FrankiePopover>
  )
}
