import { APPLICANT_SUPPORTING_DOCUMENTS_KEY as ASD } from '../applicant-supporting-documents.key'
import { TrustFormTypes } from '../model/applicant-supporting-trust-deed.model'

const AddDocKey = `${typeof ASD}-add-doc`

export const applicantSupportDocQa = {
  button: `${AddDocKey}-button`,
  form: `${AddDocKey}-form`,
  checkIcon: `${AddDocKey}-check-icon`,
  remove: `${AddDocKey}-remove`,
  downloadUrl: `${AddDocKey}-download-url`,
  noViewOptions: `${AddDocKey}-no-view-options`,
  comment: `${AddDocKey}-comment`,
  submit: `${AddDocKey}-submit`,
  scan: `${AddDocKey}-scan`,
} satisfies Record<string, `${typeof AddDocKey}-${string}`>

const AppSupportHelper = `${typeof ASD}-support-helper`

export const appSupportHelperQa = {
  loader: `${AppSupportHelper}-loader`,
} satisfies Record<string, `${typeof AppSupportHelper}-${string}`>

const AppSupportDoc = `${typeof ASD}-support-doc`

export const appSupportDocQa = {
  wrapper: `${AppSupportDoc}-wrapper`,
  notDoc: `${AppSupportDoc}-notDoc`,
  notHaveUUIDBtn: `${AppSupportDoc}-not-have-uuid`,
} satisfies Record<string, `${typeof AppSupportDoc}-${string}`>

const AppUploadDoc = `${typeof ASD}-upload-doc`

export const appUploadDocQa = {
  wrapper: `${AppUploadDoc}-wrapper`,
  loader: `${AppUploadDoc}-loader`,
} satisfies Record<string, `${typeof AppUploadDoc}-${string}`>

const AddTrustDeedKey = `${typeof ASD}-trust-deed`

export const trustDeedsDocQa = {
  wrapper: `${AddTrustDeedKey}-wrapper`,
  uploadWrapper: `${AddTrustDeedKey}-upload-wrapper`,
  tableWrapper: `${AddTrustDeedKey}-table-wrapper`,
  uploadFileButton: `${AddTrustDeedKey}-upload-document-button`,
  cancelUpload: `${AddTrustDeedKey}-upload-cancel-button`,
  uploadLoader: `${AddTrustDeedKey}-upload-loader`,
  uploadFileInput: `${AddTrustDeedKey}-upload-file-input`,
  startAnalysisButton: `${AddTrustDeedKey}-start-analysis-button`,
  addFileOnlyButton: `${AddTrustDeedKey}-add-file-only-button`,
  selectTrustType: `${AddTrustDeedKey}-select-trust-type`,
  uploadDifferentFile: `${AddTrustDeedKey}-upload-different-file`,
  table: `${AddTrustDeedKey}-trust-deeds-table`,
  loader: `${AddTrustDeedKey}-loader`,
  notDoc: `${AddTrustDeedKey}-notDoc`,
  returnToTrustDeed: `${AddTrustDeedKey}-return-to-trust-deed-button`,
  delete: `${AddTrustDeedKey}-delete-button`,
  download: `${AddTrustDeedKey}-download-button`,
  documentViewer: `${AddTrustDeedKey}-pdf-frame`,
  uploadedOn: `${AddTrustDeedKey}-uploaded-on`,
  documentLoader: `${AddTrustDeedKey}-document-loader`,
  analysisLoader: `${AddTrustDeedKey}-analysis-loader`,
  startAnalysisLoader: `${AddTrustDeedKey}-start-analysis-loader`,
  uploadTitle: `${AddTrustDeedKey}-upload-title`,
  trustDocumentAdded: `${AddTrustDeedKey}-trust-document-added`,
  editTrustData: `${AddTrustDeedKey}-edit-trust-data-button`,
  confirmationCancelButton: `${AddTrustDeedKey}-confirmation-cancel-button`,
  confirmationConfirmButton: `${AddTrustDeedKey}-confirmation-confirm-button`,
  confirmationDeleteTrustDeed: `${AddTrustDeedKey}-confirmation-delete-trust-deed`,
  confirmationCancelDeleteTrustDeedButton: `${AddTrustDeedKey}-confirmation-cancel-delete-trust-deed-button`,
  mismatchWarningFrame: `${AddTrustDeedKey}-mismatch-warning-frame`,
  autoDetectWarningFrame: `${AddTrustDeedKey}-auto-detect-warning-frame`,
  notDetectedWarningFrame: `${AddTrustDeedKey}-not-detected-warning-frame`,
  notDetectedWarningClose: `${AddTrustDeedKey}-not-detected-warning-close`,
  unknownWarningFrame: `${AddTrustDeedKey}-unknown-warning-frame`,
  unknownWarningClose: `${AddTrustDeedKey}-unknown-warning-close`,
  confirmTrustTypeButton: `${AddTrustDeedKey}-confirm-trust-type-button`,
  switchTrustTypeButton: `${AddTrustDeedKey}-switch-trust-type-button`,
  cancelTrustAnalyserButton: `${AddTrustDeedKey}-cancel-trust-analyser-button`,
  saveTrustAnalyserButton: `${AddTrustDeedKey}-save-trust-analyser-button`,
  reviewAndAgreeCheckmarkButton: `${AddTrustDeedKey}-review-and-agree-checkmark-button`,
  trustTypeSelector: `${AddTrustDeedKey}-trust-type-selector`,
  dateOfEstablishmentField: `${AddTrustDeedKey}-date-of-establishment-field`,
  nameOfTrustField: `${AddTrustDeedKey}-name-of-trust-field`,
  countryStateOfEstablishmentField: `${AddTrustDeedKey}-country-state-of-establishment-field`,
} satisfies Record<string, `${typeof AddTrustDeedKey}-${string}`>

export const trustDeedsTableQA = {
  nameCell: (idx: number) =>
    `${AddTrustDeedKey}-document-upload-name-cell${idx}`,
  statusCell: (idx: number) =>
    `${AddTrustDeedKey}-document-upload-status-cell${idx}`,
  dateCell: (idx: number) =>
    `${AddTrustDeedKey}-document-upload-date-cell${idx}`,
  uploaderCell: (idx: number) =>
    `${AddTrustDeedKey}-document-upload-uploader-cell${idx}`,
  updatedCell: (idx: number) =>
    `${AddTrustDeedKey}-document-upload-updated-cell${idx}`,
  updaterCell: (idx: number) =>
    `${AddTrustDeedKey}-document-upload-updater-cell${idx}`,
} satisfies Record<string, (idx: number) => string>

const AddTrustAnalyserEditKey = `${typeof ASD}-trust-deed-edit`
const TrustAnalyserFormKey = `${typeof ASD}-trustAnalyser-form`

export const trustAnalyserFormQa = {
  addEntity: `${TrustAnalyserFormKey}-add-entity-button`,
  removeEntity: `${TrustAnalyserFormKey}-remove-entity-button`,
} satisfies Record<string, `${typeof TrustAnalyserFormKey}-${string}`>

export const trustAnalyserEditFormQa = {
  type: (type: TrustFormTypes, idx: number) =>
    `${AddTrustAnalyserEditKey}-${type}-type-field${idx}`,
  name: (type: TrustFormTypes, idx: number) =>
    `${AddTrustAnalyserEditKey}-${type}-name-field${idx}`,
  dateOfBirth: (type: TrustFormTypes, idx: number) =>
    `${AddTrustAnalyserEditKey}-${type}-dob-field${idx}`,
  abnOrAcn: (type: TrustFormTypes, idx: number) =>
    `${AddTrustAnalyserEditKey}-${type}-registration-number-field${idx}`,
  address: (type: TrustFormTypes, idx: number) =>
    `${AddTrustAnalyserEditKey}-${type}-address-field${idx}`,
  role: (type: TrustFormTypes, idx: number) =>
    `${AddTrustAnalyserEditKey}-${type}-role-field${idx}`,
  generalBeneficiaries: (type: TrustFormTypes, idx: number) =>
    `${AddTrustAnalyserEditKey}-${type}-field${idx}`,
} satisfies Record<string, (type: TrustFormTypes, idx: number) => string>

const AddTrustAnalyserViewKey = `${typeof ASD}-trust-deed-view`

export const trustAnalyserViewFormQa = {
  type: (type: TrustFormTypes, idx: number) =>
    `${AddTrustAnalyserViewKey}-${type}-type-field${idx}`,
  name: (type: TrustFormTypes, idx: number) =>
    `${AddTrustAnalyserViewKey}-${type}-name-field${idx}`,
  dateOfBirth: (type: TrustFormTypes, idx: number) =>
    `${AddTrustAnalyserViewKey}-${type}-dob-field${idx}`,
  abnOrAcn: (type: TrustFormTypes, idx: number) =>
    `${AddTrustAnalyserViewKey}-${type}-registration-number-field${idx}`,
  address: (type: TrustFormTypes, idx: number) =>
    `${AddTrustAnalyserViewKey}-${type}-address-field${idx}`,
  role: (type: TrustFormTypes, idx: number) =>
    `${AddTrustAnalyserViewKey}-${type}-role-field${idx}`,
  generalBeneficiaries: (type: TrustFormTypes, idx: number) =>
    `${AddTrustAnalyserViewKey}-${type}-field${idx}`,
  addAssociate: (type: TrustFormTypes, idx: number) =>
    `${AddTrustAnalyserViewKey}-${type}-add-button${idx}`,
} satisfies Record<string, (type: TrustFormTypes, idx: number) => string>

const AddTrustAnalyserReferenceKey = `${typeof ASD}-trust-deed-reference`

export const trustAnalyserReferenceDetailQa = {
  name: `${AddTrustAnalyserReferenceKey}-name`,
  date: `${AddTrustAnalyserReferenceKey}-date`,
  country: `${AddTrustAnalyserReferenceKey}-country`,
} satisfies Record<string, `${typeof AddTrustAnalyserReferenceKey}-${string}`>

export const trustAnalyserReferenceQa = (type: TrustFormTypes, idx: number) =>
  `${AddTrustAnalyserReferenceKey}-${type}${idx}`
