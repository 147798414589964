import { FrankieBadgeTheme } from 'frankify/src'

import { ApplicantEntityTypes } from 'entities/applicant'
import { TrustDeedTypes } from 'entities/applicant/model/applicant-trust-analyser.model'
import { DocumentTypes } from 'entities/document'
import { SupportedFileTypes } from 'entities/document/model/document.model'
import { EnumAddressType } from 'entities/entity/model/entity.model'

import { I18nFunction, I18nKeys } from 'shared/i18n'

import {
  IDocumentInformationEntity,
  ITrustAnalyse,
  ITrustDeedsResponse,
  TrustTypes,
  ITrustDocumentInformation,
  ConfirmAnalysisResultPayload,
  ILinkedIndividuals,
  ILinkedOrganizations,
  ILinkedIndividual,
  ILinkedOrganization,
  ApplicantTrustTypes,
  IDocumentInformationAddresses,
  IValueReferenceIds,
  TrustInformationTypes,
  IDocumentInformation,
  IValueNumberReferenceIds,
} from './applicant-supporting-documents.model'
import { applicantSupportingDocumentsEn } from '../locale/applicant-supporting-documents.en'

export const TRUST_PARAM_KEY = 'trustId'

export class ApplicantUploadedTrustDeedRecord {
  data: ITrustDeedsResponse

  id: string

  idType: DocumentTypes.TRUST_DEED

  scanName?: string

  status?: TrustDeedTypes

  uploadedDate?: string

  uploadedBy?: string

  lastUpdatedDate?: string

  updatedBy?: string

  constructor(input: ITrustDeedsResponse) {
    this.data = input
    this.id = input.documentId

    this.idType = DocumentTypes.TRUST_DEED
    this.scanName = input.docScan.find(
      item => item.mimeType === SupportedFileTypes.PDF,
    )?.scanName

    this.status = input.extraData?.['trust.document.scan_status']

    this.uploadedDate = this.getUploadedDate(input.docScan)
    this.uploadedBy = '-'
    this.lastUpdatedDate = ''
    this.updatedBy = '-'
  }

  getUploadedDate(docScans: ITrustDeedsResponse['docScan']): string {
    // we need to sort the docsans find the latest date, since the docscan is not sorted from the backend
    const sortedDocScans = docScans.sort(
      (a, b) =>
        new Date(b.scanCreated ?? '').getTime() -
        new Date(a.scanCreated ?? '').getTime(),
    )
    return sortedDocScans[0].scanCreated ?? ''
  }
}

export type ApplicantUploadedTrustDeedRecordProperties =
  keyof ApplicantUploadedTrustDeedRecord

const badgeThemeMapping: Record<TrustDeedTypes, FrankieBadgeTheme> = {
  [TrustDeedTypes.CONFIRMED]: 'green',
  [TrustDeedTypes.COMPLETE]: 'yellow',
  [TrustDeedTypes.FAILED]: 'red',
  [TrustDeedTypes.PROCESSING]: 'blue',
}

export enum TrustAlertTypes {
  FAILED = 'FAILED',
  WARNING = 'WARNING',
  MISMATCH = 'MISMATCH',
  UNKNOWN = 'UNKNOWN',
}

export type TrustFormLabelProps = {
  type?: string
  name?: string
  dateIndividual?: string
  dateOrganisation?: string
  addressIndividual?: string
  addressOrganisation?: string
  role?: string
}

export enum TrustFormTypes {
  Settlor = 'settlor',
  Trustee = 'trustee',
  Appointor = 'appointor',
  GeneralBeneficiary = 'generalBeneficiary',
  SpecifiedBeneficiary = 'specifiedBeneficiary',
  UnitHolder = 'unitHolder',
  Member = 'member',
  Protector = 'protector',
}

export enum TrustKeyTypes {
  Settlor = 'settlors',
  Trustee = 'trustees',
  Appointor = 'appointors',
  GeneralBeneficiary = 'generalBeneficiaries',
  SpecifiedBeneficiary = 'specifiedBeneficiaries',
  UnitHolder = 'unitHolders',
  Member = 'members',
  Protector = 'protectors',
}

export enum TrustReferenceTypes {
  NAME_OF_TRUST = 'nameOfTrust',
  DATE_OF_ESTABLISHMENT = 'dateOfEstablishment',
  COUNTRY_OF_ESTABLISHMENT = 'countryOfEstablishment',
  SETTLORS = TrustFormTypes.Settlor,
  TRUSTEES = TrustFormTypes.Trustee,
  APPOINTORS = TrustFormTypes.Appointor,
  PROTECTORS = TrustFormTypes.Protector,
  GENERAL_BENEFICIARIES = TrustFormTypes.GeneralBeneficiary,
  SPECIFIED_BENEFICIARIES = TrustFormTypes.SpecifiedBeneficiary,
  UNIT_HOLDERS = TrustFormTypes.UnitHolder,
  MEMBERS = TrustFormTypes.Member,
}

type CheckAssociationParam = {
  party: TrustCommonFormType
  isIndividual: boolean
  role?: TrustFormTypes
  t: I18nFunction<typeof applicantSupportingDocumentsEn>
  associatedParties: TrustDeedAssociatedParty[]
}

export type TrustDeedAssociatedParty = {
  name: string
  entityType?: ApplicantEntityTypes
  entityId?: string
  roles: { type: string; typeDescription: string }[]
  trustAnalysisId?: string
  abnOrAcn?: string
}

export type PartialSpecificTrustFormTypes =
  | TrustFormTypes.Settlor
  | TrustFormTypes.Trustee
  | TrustFormTypes.Appointor
  | TrustFormTypes.UnitHolder
  | TrustFormTypes.Member
  | TrustFormTypes.Protector

export type SpecificTrustFormTypes =
  | PartialSpecificTrustFormTypes
  | TrustFormTypes.GeneralBeneficiary
  | TrustFormTypes.SpecifiedBeneficiary

export type TrustFormKeys =
  | PartialSpecificTrustFormTypes
  | 'beneficiary.specifiedBeneficiary'
  | 'beneficiary.generalBeneficiary'

/**
 * @memberof ITrustDeedAnalysisResult
 * @description get response data from applicantSupportingDocumentApi.getAnalysisResult
 * and map response to TrustDetailForm
 * get documentInformation: analyses.documentInformation.trust
 */
export type TrustDetailForm = {
  type: TrustTypes | undefined // analyses.documentInformation.trust.type
  name: string // analyses.documentInformation.trust.name.value
  date: string // analyses.documentInformation.trust.establishment.date.normalized
  country: string // establishment.country.value - establishment.subdivision.value
}

/**
 * @memberof ITrustDeedAnalysisResult
 * @memberof ILinkedIndividuals
 * @memberof ILinkedOrganizations
 * @memberof IDocumentInformationEntity
 *
 * @description get response data from applicantSupportingDocumentApi.getAnalysisResult
 * and map response to TrustSettlorForm
 *
 * get entityId: analyses.documentInformation[key][x].entityId
 * get entityType: analyses.documentInformation[key][x].entityType
 *
 * get linkedIndividuals for entityType of INDIVIDUAL : linkedIndividuals[entityId]
 * get linkedOrganization for entityType of ORGANIZATION: linkedOrganization[entityId]
 */
export type TrustCommonFormType = {
  /**
   * linkedIndividuals[entityId].entityType
   * linkedOrganization[entityId].entityType
   */
  type: ApplicantTrustTypes | undefined
  /**
   * Identifier of the linked entity
   */
  entityId: string
  /**
   * form index to linked the reference ids
   */
  index?: number
  /**
   * linkedIndividuals[entityId].name.displayName
   * linkedOrganization[entityId].details.name.value
   */
  name?: string
  /**
   * linkedIndividuals[entityId].addresses[type === RESIDENTIAL].unstructuredLongForm
   * linkedOrganization[entityId].addresses[type === PLACE_OF_BUSINESS || type === REGISTERED_OFFICE].unstructuredLongForm
   */
  address?: string
  /**
   * linkedIndividuals[entityId].dateOfBirth.normalized
   */
  dateOfBirth?: string
  /**
   * linkedOrganization[entityId].details.registrationDetails[0].registrationNumber
   */
  abnOrAcn?: string
  /**
   * trust.typeInformation.selfManagedSuperFund.members[x].unstructuredRole
   */
  role?: string
}

export type TrustGeneralFormType = IValueReferenceIds & { index?: number }

/**
 * @key trust.settlors
 */
export type TrustSettlorForm = TrustCommonFormType

/**
 * @key trust.trustees
 */
export type TrustTrusteeForm = TrustCommonFormType

/**
 * @memberof TrustTypes.DISCRETIONARY
 * @key trust.typeInformation.discretionary.appointors
 */
export type TrustAppointorForm = TrustCommonFormType

/**
 * @memberof TrustTypes.DISCRETIONARY
 * @key trust.typeInformation.discretionary.generalBeneficiaries
 * @key trust.typeInformation.discretionary.specifiedBeneficiaries
 */
export type TrustBeneficiaryForm = {
  generalBeneficiary: TrustGeneralFormType[] // trust.typeInformation.discretionary.generalBeneficiaries[x].value
  specifiedBeneficiary: TrustCommonFormType[]
}

/**
 * @memberof TrustTypes.UNIT
 * @key trust.typeInformation.unit.unitHolders
 */
export type TrustUnitHolderForm = TrustCommonFormType

/**
 * @memberof TrustTypes.DISCRETIONARY
 * @key trust.typeInformation.discretionary.protectors
 */
export type TrustProtectorForm = TrustCommonFormType

/**
 * @memberof TrustTypes.SMSF
 * @key trust.typeInformation.selfManagedSuperFund.members
 * @description address field would be a role in Individual type
 */
export type TrustMemberForm = TrustCommonFormType & {
  role?: string // trust.typeInformation.selfManagedSuperFund.members[x].unstructuredRole
}

export interface ITrustDeedForm {
  trustDetail: TrustDetailForm
  settlor: TrustSettlorForm[]
  trustee: TrustTrusteeForm[]
  appointor: TrustAppointorForm[]
  beneficiary: TrustBeneficiaryForm
  unitHolder: TrustUnitHolderForm[]
  member: TrustMemberForm[]
  protector: TrustProtectorForm[]
  checked: boolean
}

export const defaultTrustDetailForm: TrustDetailForm = {
  type: undefined,
  name: '',
  date: '',
  country: '',
}

export const commonDefaultForm: TrustCommonFormType = {
  type: ApplicantTrustTypes.Individual,
  name: '',
  abnOrAcn: '',
  dateOfBirth: '',
  address: '',
  entityId: '',
  role: '',
  index: 0,
}

export const commonGeneralBeneficiaryForm: TrustGeneralFormType = {
  value: '',
  referenceIds: [],
  index: 0,
}

const defaultSettlorForm: TrustSettlorForm = commonDefaultForm

const defaultTrusteeForm: TrustTrusteeForm = commonDefaultForm

const defaultAppointorForm: TrustAppointorForm = commonDefaultForm

const defaultUnitHolderForm: TrustUnitHolderForm = commonDefaultForm

const defaultProtectorForm: TrustProtectorForm = commonDefaultForm

const defaultMemberForm: TrustMemberForm = commonDefaultForm

const defaultBeneficiaryForm: TrustBeneficiaryForm = {
  generalBeneficiary: [commonGeneralBeneficiaryForm],
  specifiedBeneficiary: [commonDefaultForm],
}

export const defaultTrustForm = {
  trustDetail: defaultTrustDetailForm,
  settlor: [defaultSettlorForm],
  trustee: [defaultTrusteeForm],
  appointor: [defaultAppointorForm],
  beneficiary: defaultBeneficiaryForm,
  unitHolder: [defaultUnitHolderForm],
  member: [defaultMemberForm],
  protector: [defaultProtectorForm],
  checked: false,
}

export const getReferenceType = {
  [TrustFormTypes.Settlor]: TrustReferenceTypes.SETTLORS,
  [TrustFormTypes.Trustee]: TrustReferenceTypes.TRUSTEES,
  [TrustFormTypes.Appointor]: TrustReferenceTypes.APPOINTORS,
  [TrustFormTypes.Protector]: TrustReferenceTypes.PROTECTORS,
  [TrustFormTypes.UnitHolder]: TrustReferenceTypes.UNIT_HOLDERS,
  [TrustFormTypes.Member]: TrustReferenceTypes.MEMBERS,
  [TrustFormTypes.GeneralBeneficiary]:
    TrustReferenceTypes.GENERAL_BENEFICIARIES,
  [TrustFormTypes.SpecifiedBeneficiary]:
    TrustReferenceTypes.SPECIFIED_BENEFICIARIES,
}

const START_REFERENCE_NUMBER = 2
export const getReferenceNumber = (formIndex: number) =>
  formIndex + START_REFERENCE_NUMBER

export const getReferenceAlphabet = (fieldIndex: number) => {
  const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('')
  return alphabet[fieldIndex] || alphabet[0]
}

const changeAddress = (
  unstructuredLongForm: string,
  addressType: EnumAddressType,
  addresses?: IDocumentInformationAddresses[],
): IDocumentInformationAddresses[] => {
  if (addresses) {
    if (addresses.find(x => x.type === addressType)) {
      return addresses.map(x =>
        x.type === addressType ? { ...x, unstructuredLongForm } : x,
      )
    }
    return [
      { ...addresses[0], unstructuredLongForm },
      ...addresses.slice(1, addresses.length),
    ]
  }

  return [
    {
      unstructuredLongForm,
      country: '',
      referenceIds: [],
    },
  ]
}

export const linkedIndividualsForm = (
  {
    entityId,
    dateOfBirth: dob,
    address,
    name: displayName,
  }: TrustCommonFormType,
  originalData?: ILinkedIndividual,
): ILinkedIndividuals => {
  const splitDateOfBirth = (dob?: string) => {
    if (!dob) return {}

    const [year, month, day] = dob.split('-')

    const dateRefIds = originalData?.dateOfBirth?.referenceIds

    return {
      dateOfBirth: {
        ...(dateRefIds && { referenceIds: dateRefIds }),
        day,
        month,
        normalized: dob,
        unstructured: dob,
        year,
      },
    }
  }

  const processAddresses = (address?: string) => {
    if (!address) return {}

    return {
      addresses: changeAddress(
        address,
        EnumAddressType.RESIDENTIAL,
        originalData?.addresses,
      ),
    }
  }

  const processName = (displayName?: string) => {
    if (!displayName) return {}

    return {
      name: {
        familyName: '',
        ...originalData?.name,
        displayName,
      },
    }
  }

  return {
    [entityId]: {
      entityId,
      entityType: ApplicantTrustTypes.Individual,
      ...splitDateOfBirth(dob),
      ...processAddresses(address),
      ...processName(displayName),
    },
  }
}

export const linkedOrganizationsForm = (
  { entityId, address, abnOrAcn, name }: TrustCommonFormType,
  originalData?: ILinkedOrganization,
): ILinkedOrganizations => {
  const processAddresses = (address?: string) => {
    if (!address) return {}

    return {
      addresses: changeAddress(
        address,
        EnumAddressType.REGISTERED_OFFICE,
        originalData?.addresses,
      ),
    }
  }

  const processDetails = (nameValue?: string, abnOrAcn?: string) => {
    let nameObj = {}
    const nameRefIds = originalData?.details?.name?.referenceIds

    if (nameValue) {
      nameObj = {
        name: {
          ...(nameRefIds && { referenceIds: nameRefIds }),
          value: nameValue,
        },
      }
    }

    let registrationDetailsObj = {}

    const originalRegistrationDetails =
      originalData?.details?.registrationDetails

    if (abnOrAcn) {
      if (originalRegistrationDetails && originalRegistrationDetails.length) {
        registrationDetailsObj = {
          registrationDetails: [
            {
              ...originalRegistrationDetails[0],
              registrationNumber: abnOrAcn,
            },
            ...originalRegistrationDetails.slice(
              1,
              originalRegistrationDetails.length,
            ),
          ],
        }
      } else {
        registrationDetailsObj = {
          registrationDetails: [
            {
              referenceIds: [],
              registrationNumber: abnOrAcn,
            },
          ],
        }
      }
    }

    return {
      details: {
        ...nameObj,
        ...registrationDetailsObj,
      },
    }
  }

  return {
    [entityId]: {
      entityId,
      entityType: ApplicantTrustTypes.Organization,
      ...processAddresses(address),
      ...processDetails(name, abnOrAcn),
    },
  }
}

export const trustDeedStatusesI18n: Record<
  TrustDeedTypes,
  I18nKeys<typeof applicantSupportingDocumentsEn>
> = {
  [TrustDeedTypes.CONFIRMED]: 'trustDeedStatusType.confirmed',
  [TrustDeedTypes.COMPLETE]: 'trustDeedStatusType.completed',
  [TrustDeedTypes.FAILED]: 'trustDeedStatusType.failed',
  [TrustDeedTypes.PROCESSING]: 'trustDeedStatusType.processing',
}

export function getStatusBadge(
  applicant: ApplicantUploadedTrustDeedRecord,
  filterT: I18nFunction<typeof applicantSupportingDocumentsEn>,
): {
  theme: FrankieBadgeTheme
  text: string
} {
  return {
    theme: applicant.status ? badgeThemeMapping[applicant.status] : 'lightgrey',
    text: applicant.status
      ? filterT(trustDeedStatusesI18n[applicant.status])
      : filterT('trustDeedStatusType.no_status_kvp'),
  }
}

function getTrustAddress(
  addresses: IDocumentInformationAddresses[] | undefined,
  type: ApplicantTrustTypes,
): string {
  if (addresses) {
    if (type === ApplicantTrustTypes.Individual) {
      return (
        addresses.find(({ type }) => type === EnumAddressType.RESIDENTIAL)
          ?.unstructuredLongForm ?? addresses[0].unstructuredLongForm
      )
    }
    return (
      addresses.find(({ type }) => type === EnumAddressType.REGISTERED_OFFICE)
        ?.unstructuredLongForm ?? addresses[0].unstructuredLongForm
    )
  }
  return ''
}

export const mapLinkedData = (
  data: IDocumentInformationEntity[],
  trust: ITrustDocumentInformation,
  shouldShowForm = false,
): TrustCommonFormType[] => {
  if (shouldShowForm && data.length === 0) return [commonDefaultForm]
  return data.map((item, index) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (trust.linkedIndividuals && trust.linkedIndividuals[item.entityId]) {
      const { addresses, entityType, name, dateOfBirth } =
        trust.linkedIndividuals[item.entityId]

      return {
        entityId: item.entityId,
        type: entityType,
        name: name?.displayName,
        address: getTrustAddress(addresses, entityType),
        dateOfBirth: dateOfBirth?.normalized || dateOfBirth?.unstructured || '',
        role: item.unstructuredRole,
        abnOrAcn: '',
        index,
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (trust.linkedOrganizations && trust.linkedOrganizations[item.entityId]) {
      const { addresses, entityType, details } =
        trust.linkedOrganizations[item.entityId]

      const abnOrAcn = details?.registrationDetails?.length
        ? details.registrationDetails[0].registrationNumber
        : ''

      return {
        entityId: item.entityId,
        type: entityType,
        name: details?.name?.value,
        address: getTrustAddress(addresses, entityType),
        abnOrAcn,
        index,
      }
    }

    return {
      ...commonDefaultForm,
      type: item.entityType,
      entityId: item.entityId,
      referenceIds: item.referenceIds,
      role: item.unstructuredRole,
      index,
    }
  })
}

export function mapDataToForm(data: ITrustAnalyse): ITrustDeedForm {
  if (data.documentInformation) {
    const { trust } = data.documentInformation
    const { detected, provided } = trust.type

    if (detected === TrustTypes.UNKNOWN) {
      return defaultTrustForm
    }

    const { settlors = [], trustees = [], name, establishment } = trust
    const { country, date } = establishment || {}
    const { discretionary, selfManagedSuperFund, unit } = trust.typeInformation
    const { unitHolders = [] } = unit || {}
    const { members = [] } = selfManagedSuperFund || {}
    const {
      appointors = [],
      generalBeneficiaries = [],
      specifiedBeneficiaries = [],
      protectors = [],
    } = discretionary || {}

    const type = provided || detected

    const trustDetail = {
      type,
      name: name?.value || '',
      date: date?.normalized || '',
      country: country?.value || '',
    }

    const isDiscretionary = type === TrustTypes.DISCRETIONARY
    const isUnit = type === TrustTypes.UNIT
    const isSmsf = type === TrustTypes.SMSF

    const generalBeneficiary = generalBeneficiaries.map((item, index) => ({
      ...item,
      index,
    }))

    const getGeneralBeneficiary = (): TrustGeneralFormType[] =>
      generalBeneficiaries.length === 0 && isDiscretionary
        ? defaultBeneficiaryForm.generalBeneficiary
        : generalBeneficiary

    return {
      trustDetail,
      settlor: mapLinkedData(settlors, trust, true),
      trustee: mapLinkedData(trustees, trust, true),
      appointor: mapLinkedData(appointors, trust, isDiscretionary),
      unitHolder: mapLinkedData(unitHolders, trust, isUnit),
      member: mapLinkedData(members, trust, isSmsf),
      protector: mapLinkedData(protectors, trust, isDiscretionary),
      beneficiary: {
        generalBeneficiary: getGeneralBeneficiary(),
        specifiedBeneficiary: mapLinkedData(
          specifiedBeneficiaries,
          trust,
          isDiscretionary,
        ),
      },
      checked: false,
    }
  }

  return defaultTrustForm
}

function generateNextNumber(arr: string[]): string {
  const numberArr = arr.map(x => parseInt(x.slice(-5), 10))
  const highestNumber = numberArr.length ? Math.max(...numberArr) + 1 : 1
  const nextUUIDStr = `00000000-0000-0000-0000-00000000000${highestNumber}`
  return nextUUIDStr
}

function updateOriginalData(
  form: commonFormType,
  document: IDocumentInformationEntity[] = [],
): IDocumentInformationEntity[] {
  const { formData, index } = form
  const { entityId, type, name } = formData

  if (!name) return []
  if (!type) return document

  const existingDoc = document[index]
  const copyDoc = [...document]
  const idType = { entityId, entityType: type }

  const role =
    form.formKey === TrustFormTypes.Member && formData.role
      ? { unstructuredRole: formData.role }
      : {}

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (existingDoc) {
    copyDoc[index] = {
      ...existingDoc,
      ...idType,
      ...role,
    }
  } else {
    copyDoc.push({
      ...idType,
      ...role,
    })
  }
  return copyDoc
}

type MapTrustDocumentType = {
  trustDocument: ITrustDocumentInformation
  value: IDocumentInformationEntity[] | IValueReferenceIds[]
  formType: TrustKeyTypes
  typeInformationKey?: TrustInformationTypes
}

function mapDocumentInformationEntity({
  trustDocument,
  value,
  formType,
  typeInformationKey,
}: MapTrustDocumentType): ITrustDocumentInformation {
  if (typeInformationKey) {
    const { typeInformation } = trustDocument
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [typeInformationKey]: _, ...restTypeInformation } = typeInformation

    const isUnit = typeInformationKey === TrustInformationTypes.unit

    // TODO: the total units in the unit holder type it is mandatory
    // and it is hardcoded to 1, need to update this value from the UI
    const totalUnits: IValueNumberReferenceIds = {
      value: 1,
    }

    return {
      ...trustDocument,
      typeInformation: {
        ...restTypeInformation,
        [typeInformationKey]: {
          ...typeInformation[typeInformationKey],
          ...(isUnit && { totalUnits }),
          [formType]: value,
        },
      },
    }
  }

  const isSettlorOrTrustee =
    formType === TrustKeyTypes.Settlor || formType === TrustKeyTypes.Trustee

  if (isSettlorOrTrustee) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [formType]: _, ...restTrustDoc } = trustDocument
    return {
      ...restTrustDoc,
      ...(value.length && { [formType]: value }),
      typeInformation: {
        ...trustDocument.typeInformation,
      },
    }
  }
  return trustDocument
}

function updateTrustDocumentByForm(
  form: commonFormType,
  documentInformation: IDocumentInformation,
) {
  const { formKey } = form
  const { trust } = documentInformation
  const { typeInformation } = trust
  const { discretionary, selfManagedSuperFund, unit } = typeInformation

  const updatedDoc = documentInformation

  if (formKey === TrustFormTypes.Settlor) {
    const settlors = updateOriginalData(form, trust.settlors)
    updatedDoc.trust = mapDocumentInformationEntity({
      trustDocument: updatedDoc.trust,
      value: settlors,
      formType: TrustKeyTypes.Settlor,
    })
  }

  if (formKey === TrustFormTypes.Trustee) {
    const trustees = updateOriginalData(form, trust.trustees)
    updatedDoc.trust = mapDocumentInformationEntity({
      trustDocument: updatedDoc.trust,
      value: trustees,
      formType: TrustKeyTypes.Trustee,
    })
  }

  if (formKey === TrustFormTypes.Appointor) {
    const appointors = updateOriginalData(form, discretionary?.appointors)
    updatedDoc.trust = mapDocumentInformationEntity({
      trustDocument: updatedDoc.trust,
      value: appointors,
      typeInformationKey: TrustInformationTypes.discretionary,
      formType: TrustKeyTypes.Appointor,
    })
  }

  if (formKey === TrustFormTypes.Protector) {
    const protectors = updateOriginalData(form, discretionary?.protectors)
    updatedDoc.trust = mapDocumentInformationEntity({
      trustDocument: updatedDoc.trust,
      value: protectors,
      typeInformationKey: TrustInformationTypes.discretionary,
      formType: TrustKeyTypes.Protector,
    })
  }

  if (formKey === TrustFormTypes.SpecifiedBeneficiary) {
    const specifiedBeneficiaries = updateOriginalData(
      form,
      discretionary?.specifiedBeneficiaries,
    )
    updatedDoc.trust = mapDocumentInformationEntity({
      trustDocument: updatedDoc.trust,
      value: specifiedBeneficiaries,
      typeInformationKey: TrustInformationTypes.discretionary,
      formType: TrustKeyTypes.SpecifiedBeneficiary,
    })
  }

  if (formKey === TrustFormTypes.Member) {
    const members = updateOriginalData(form, selfManagedSuperFund?.members)
    updatedDoc.trust = mapDocumentInformationEntity({
      trustDocument: updatedDoc.trust,
      value: members,
      typeInformationKey: TrustInformationTypes.selfManagedSuperFund,
      formType: TrustKeyTypes.Member,
    })
  }

  if (formKey === TrustFormTypes.UnitHolder) {
    const unitHolders = updateOriginalData(form, unit?.unitHolders)
    updatedDoc.trust = mapDocumentInformationEntity({
      trustDocument: updatedDoc.trust,
      value: unitHolders,
      typeInformationKey: TrustInformationTypes.unit,
      formType: TrustKeyTypes.UnitHolder,
    })
  }
}

function removeOriginalDocEntityByForm(
  key: IDocumentInformationEntity[] | undefined,
  form: TrustCommonFormType[],
) {
  if (key && key.length > 0 && form.length > 0) {
    return key.filter(x => form.map(y => y.entityId).includes(x.entityId))
  }
  return []
}

/**
 * @description Remove all document entity id by form
 */
function removeAllDocEntityByForm(
  documentInformation: IDocumentInformation,
  form: ITrustDeedForm,
) {
  const { trust } = documentInformation
  const { typeInformation } = trust
  const { discretionary, selfManagedSuperFund, unit } = typeInformation
  const updatedDoc = documentInformation

  const settlors = removeOriginalDocEntityByForm(trust.settlors, form.settlor)

  updatedDoc.trust = mapDocumentInformationEntity({
    trustDocument: updatedDoc.trust,
    value: settlors,
    formType: TrustKeyTypes.Settlor,
  })

  const trustees = removeOriginalDocEntityByForm(trust.trustees, form.trustee)

  updatedDoc.trust = mapDocumentInformationEntity({
    trustDocument: updatedDoc.trust,
    value: trustees,
    formType: TrustKeyTypes.Trustee,
  })

  if (selfManagedSuperFund) {
    const members = removeOriginalDocEntityByForm(
      selfManagedSuperFund.members,
      form.member,
    )

    updatedDoc.trust = mapDocumentInformationEntity({
      trustDocument: updatedDoc.trust,
      value: members,
      typeInformationKey: TrustInformationTypes.selfManagedSuperFund,
      formType: TrustKeyTypes.Member,
    })
  }

  if (unit) {
    const unitHolder = removeOriginalDocEntityByForm(
      unit.unitHolders,
      form.unitHolder,
    )

    updatedDoc.trust = mapDocumentInformationEntity({
      trustDocument: updatedDoc.trust,
      value: unitHolder,
      typeInformationKey: TrustInformationTypes.unit,
      formType: TrustKeyTypes.UnitHolder,
    })
  }

  if (discretionary) {
    const specifiedBeneficiaries = removeOriginalDocEntityByForm(
      discretionary.specifiedBeneficiaries,
      form.beneficiary.specifiedBeneficiary,
    )

    const protectors = removeOriginalDocEntityByForm(
      discretionary.protectors,
      form.protector,
    )

    const appointors = removeOriginalDocEntityByForm(
      discretionary.appointors,
      form.appointor,
    )

    updatedDoc.trust = mapDocumentInformationEntity({
      trustDocument: updatedDoc.trust,
      value: specifiedBeneficiaries,
      typeInformationKey: TrustInformationTypes.discretionary,
      formType: TrustKeyTypes.SpecifiedBeneficiary,
    })

    updatedDoc.trust = mapDocumentInformationEntity({
      trustDocument: updatedDoc.trust,
      value: protectors,
      typeInformationKey: TrustInformationTypes.discretionary,
      formType: TrustKeyTypes.Protector,
    })

    updatedDoc.trust = mapDocumentInformationEntity({
      trustDocument: updatedDoc.trust,
      value: appointors,
      typeInformationKey: TrustInformationTypes.discretionary,
      formType: TrustKeyTypes.Appointor,
    })
  }
}

function getListOfDocEntityIds(form: ITrustDeedForm): string[] {
  return [
    ...form.settlor,
    ...form.trustee,
    ...form.appointor,
    ...form.unitHolder,
    ...form.member,
    ...form.beneficiary.specifiedBeneficiary,
    ...form.protector,
  ].map(x => x.entityId)
}

function mapLinkedIndividualReferenceIds(data: ILinkedIndividual): string[] {
  const addressReferences =
    data.addresses?.flatMap(x => x.referenceIds || []) || []
  const dobReferences = data.dateOfBirth?.referenceIds || []
  const nameReferences = data.name?.referenceIds || []
  return [...addressReferences, ...dobReferences, ...nameReferences]
}

function mapLinkedOrganizationReferenceIds(
  data: ILinkedOrganization,
): string[] {
  const addressReferences =
    data.addresses?.flatMap(x => x.referenceIds || []) || []
  const nameReferences = data.details?.name?.referenceIds || []
  const abnOrAcnReferences =
    data.details?.registrationDetails?.flatMap(x => x.referenceIds || []) || []
  return [...addressReferences, ...nameReferences, ...abnOrAcnReferences]
}

// eslint-disable-next-line complexity
function filterReferencesIds(analysis: ConfirmAnalysisResultPayload) {
  const { documentInformation, references = {} } = analysis
  const { trust } = documentInformation || {}
  const {
    certification,
    execution,
    establishment,
    name,
    settlors = [],
    trustees = [],
    typeInformation,
    linkedIndividuals = {},
    linkedOrganizations = {},
  } = trust || {}
  const { discretionary, selfManagedSuperFund, unit } = typeInformation || {}
  const {
    appointors = [],
    protectors = [],
    specifiedBeneficiaries = [],
    generalBeneficiaries = [],
  } = discretionary || {}
  const { unitHolders = [] } = unit || {}
  const { members = [] } = selfManagedSuperFund || {}

  const linkedIndividualReferences: string[] = Object.values(
    linkedIndividuals,
  ).flatMap(mapLinkedIndividualReferenceIds)
  const linkedOrganizationReferences: string[] = Object.values(
    linkedOrganizations,
  ).flatMap(mapLinkedOrganizationReferenceIds)

  const certificationReferences = certification?.date?.referenceIds || []
  const executionReferences = execution?.date?.referenceIds || []
  const nameReferences = name?.referenceIds || []
  const dateReferences = establishment?.date?.referenceIds || []
  const countryReferences = establishment?.country?.referenceIds || []
  const subdivisionReferences = establishment?.subdivision?.referenceIds || []
  const settlorReferences = settlors.flatMap(x => x.referenceIds || [])
  const trusteeReferences = trustees.flatMap(x => x.referenceIds || [])
  const appointorReferences = appointors.flatMap(x => x.referenceIds || [])
  const protectorReferences = protectors.flatMap(x => x.referenceIds || [])
  const specifiedBeneficiaryReferences = specifiedBeneficiaries.flatMap(
    x => x.referenceIds || [],
  )
  const generalBeneficiariesReferences = generalBeneficiaries.flatMap(
    x => x.referenceIds || [],
  )
  const unitHolderReferences = unitHolders.flatMap(x => x.referenceIds || [])
  const memberReferences = members.flatMap(x => x.referenceIds || [])

  const docReferenceIds = [
    ...linkedIndividualReferences,
    ...linkedOrganizationReferences,
    ...certificationReferences,
    ...executionReferences,
    ...nameReferences,
    ...dateReferences,
    ...countryReferences,
    ...subdivisionReferences,
    ...settlorReferences,
    ...trusteeReferences,
    ...appointorReferences,
    ...protectorReferences,
    ...specifiedBeneficiaryReferences,
    ...generalBeneficiariesReferences,
    ...unitHolderReferences,
    ...memberReferences,
  ]

  const filteredByKey = Object.fromEntries(
    Object.entries(references).filter(([key, _]) =>
      docReferenceIds.includes(key),
    ),
  )

  const updatedAnalysis = analysis
  updatedAnalysis.references = filteredByKey
}

function getListOfLinkedEntityIds(
  analysis: ConfirmAnalysisResultPayload,
): string[] {
  const { documentInformation } = analysis
  const { trust } = documentInformation || {}

  const listOfLinkedIndividualEntityIds = trust?.linkedIndividuals
    ? Object.keys(trust.linkedIndividuals)
    : []
  const listOfLinkedOrganizationEntityIds = trust?.linkedOrganizations
    ? Object.keys(trust.linkedOrganizations)
    : []

  return [
    ...listOfLinkedIndividualEntityIds,
    ...listOfLinkedOrganizationEntityIds,
  ]
}

function filterLinkedEntityByDoc(
  analysis: ConfirmAnalysisResultPayload,
  listOfDocEntityIds: string[],
) {
  const { documentInformation } = analysis
  const { trust } = documentInformation || {}
  const { linkedIndividuals = {}, linkedOrganizations = {} } = trust || {}
  const updatedTrust = trust

  const filteredLinkedIndividuals = Object.fromEntries(
    Object.entries(linkedIndividuals).filter(([key]) =>
      listOfDocEntityIds.includes(key),
    ),
  )

  const filteredLinkedOrganizations = Object.fromEntries(
    Object.entries(linkedOrganizations).filter(([key]) =>
      listOfDocEntityIds.includes(key),
    ),
  )

  if (updatedTrust) {
    updatedTrust.linkedIndividuals = filteredLinkedIndividuals
    updatedTrust.linkedOrganizations = filteredLinkedOrganizations
  }
}

function removeLinkedEntity(
  analysis: ConfirmAnalysisResultPayload,
  entityId: string,
) {
  const { documentInformation } = analysis
  const { trust } = documentInformation || {}
  const { linkedIndividuals = {}, linkedOrganizations = {} } = trust || {}
  delete linkedIndividuals[entityId]
  delete linkedOrganizations[entityId]
}

const removeDuplicatedItem = (arr: string[], value: string): string[] =>
  arr.filter(
    (item, index, self) => self.indexOf(value) !== index || item !== value,
  )

type commonFormType = {
  formData: TrustMemberForm
  formKey: TrustFormTypes
  index: number
}

function getLinkedEntityData(
  entityId: string,
  trust: ITrustDocumentInformation,
): {
  linkedEntityType?: ApplicantTrustTypes
  linkedAddress?: string
  linkedDob?: string
  linkedName?: string
  linkedAbnOrAcn?: string
} {
  const getLinkedIndividual = trust.linkedIndividuals
    ? trust.linkedIndividuals[entityId]
    : []

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (getLinkedIndividual) {
    const { entityType, addresses, dateOfBirth, name } =
      getLinkedIndividual as ILinkedIndividual
    const { displayName } = name || {}
    const { normalized, unstructured } = dateOfBirth || {}
    const linkedAddress = getTrustAddress(addresses, entityType)
    return {
      linkedEntityType: entityType,
      linkedAddress,
      linkedDob: normalized || unstructured || '',
      linkedName: displayName,
    }
  }

  const getLinkedOrganization = trust.linkedOrganizations
    ? trust.linkedOrganizations[entityId]
    : []

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (getLinkedOrganization) {
    const { entityType, addresses, details } =
      getLinkedOrganization as ILinkedOrganization
    const { name, registrationDetails = [] } = details || {}
    const displayName = name ? name.value : ''
    const linkedAddress = getTrustAddress(addresses, entityType)
    const abnOrAcn = registrationDetails.length
      ? registrationDetails[0].registrationNumber
      : ''
    return {
      linkedEntityType: entityType,
      linkedAddress,
      linkedAbnOrAcn: abnOrAcn,
      linkedName: displayName,
    }
  }

  return {}
}

// Helper function to map common form types to linked entities
function mapCommonLinkedData(
  { formData, formKey, index }: commonFormType,
  entityId: string,
  documentInformation: IDocumentInformation,
) {
  const { type } = formData
  const { trust } = documentInformation
  const updatedTrust = trust

  const isIndividualType = type === ApplicantTrustTypes.Individual
  const isOrganizationType = type === ApplicantTrustTypes.Organization

  if (isIndividualType) {
    const individual = trust.linkedIndividuals
      ? trust.linkedIndividuals[entityId]
      : undefined
    updatedTrust.linkedIndividuals = {
      ...trust.linkedIndividuals,
      ...linkedIndividualsForm({ ...formData, entityId }, individual),
    }
  } else if (isOrganizationType) {
    const organization = trust.linkedOrganizations
      ? trust.linkedOrganizations[entityId]
      : undefined
    updatedTrust.linkedOrganizations = {
      ...trust.linkedOrganizations,
      ...linkedOrganizationsForm({ ...formData, entityId }, organization),
    }
  }

  const newFormData = {
    ...formData,
    entityId,
  }

  updateTrustDocumentByForm(
    { formKey, formData: newFormData, index },
    documentInformation,
  )
}

function updateTrustDetailForm(
  documentInformation: IDocumentInformation,
  form: TrustDetailForm,
) {
  const { trust } = documentInformation
  const { type, name, date, country } = form
  const updatedTrust = trust

  const dateRefIds = trust.establishment?.date?.referenceIds
  const countryRefIds = trust.establishment?.country?.referenceIds
  const nameRefIds = trust.name?.referenceIds

  updatedTrust.type = {
    ...(trust.type.provided && { provided: type }),
    detected: trust.type.detected,
  }

  updatedTrust.name = {
    ...(nameRefIds && { referenceIds: nameRefIds }),
    value: name,
  }

  const [year, month, day] = date.split('-')

  updatedTrust.establishment = {
    ...updatedTrust.establishment,
    ...(date && {
      date: {
        ...(dateRefIds && { referenceIds: dateRefIds }),
        normalized: date,
        unstructured: date,
        year,
        month,
        day,
      },
    }),
    ...(country && {
      country: {
        ...(countryRefIds && { referenceIds: countryRefIds }),
        value: country,
      },
    }),
  }
}

function shouldCreateNewEntity(
  trust: ITrustDocumentInformation,
  formData: TrustCommonFormType,
): boolean {
  const { entityId, type, name, address, abnOrAcn, dateOfBirth } = formData

  const {
    linkedEntityType,
    linkedName,
    linkedAddress,
    linkedAbnOrAcn,
    linkedDob,
  } = getLinkedEntityData(entityId, trust)

  const isIndividual = type === ApplicantTrustTypes.Individual
  const isOrganization = type === ApplicantTrustTypes.Organization

  if (type !== linkedEntityType) return true
  if (name !== linkedName) return true
  if (address !== linkedAddress) return true
  if (abnOrAcn !== linkedAbnOrAcn && isOrganization) return true
  if (dateOfBirth !== linkedDob && isIndividual) return true
  return false
}

/**
 * @description Validation of form goes here.
 * Currently it is checking and filter list if name is empty or value from generalBeneficiary is empty
 * @param form ITrustDeedForm
 * @returns ITrustDeedForm
 */
const validationFormCheck = (form: ITrustDeedForm): ITrustDeedForm => {
  const {
    trustDetail,
    settlor,
    trustee,
    appointor,
    unitHolder,
    member,
    protector,
    beneficiary,
    checked,
  } = form

  const { generalBeneficiary, specifiedBeneficiary } = beneficiary

  const filteredAppointor = appointor.filter(x => x.name)
  const filteredSettlor = settlor.filter(x => x.name)
  const filteredTrustee = trustee.filter(x => x.name)
  const filteredUnitHolder = unitHolder.filter(x => x.name)
  const filteredMember = member.filter(x => x.name)
  const filteredProtector = protector.filter(x => x.name)
  const filteredGeneralBeneficiary = generalBeneficiary.filter(x => x.value)
  const filteredSpecifiedBeneficiary = specifiedBeneficiary.filter(x => x.name)

  return {
    trustDetail,
    appointor: filteredAppointor,
    member: filteredMember,
    protector: filteredProtector,
    settlor: filteredSettlor,
    trustee: filteredTrustee,
    unitHolder: filteredUnitHolder,
    beneficiary: {
      generalBeneficiary: filteredGeneralBeneficiary,
      specifiedBeneficiary: filteredSpecifiedBeneficiary,
    },
    checked,
  }
}

export function mapTrustDeedFormToAnalysis(
  trustForm: ITrustDeedForm,
  analysis: ConfirmAnalysisResultPayload,
): ConfirmAnalysisResultPayload | undefined {
  if (!analysis.documentInformation) return undefined
  const { documentInformation } = analysis
  const { trust } = documentInformation

  const form = validationFormCheck(trustForm)

  const listOfDocEntityIds = getListOfDocEntityIds(form)
  let docEntityIdsCopy = listOfDocEntityIds.slice()

  // remove all original document entity by form
  removeAllDocEntityByForm(documentInformation, form)

  // filter all linked entities data by document entity ids
  filterLinkedEntityByDoc(analysis, listOfDocEntityIds)

  // map trust detail form into trust document information type
  updateTrustDetailForm(documentInformation, form.trustDetail)

  // map each document form into a corresponding type in a trust document information
  ;[
    form.settlor,
    form.trustee,
    form.appointor,
    form.unitHolder,
    form.member,
    form.beneficiary.specifiedBeneficiary,
    form.protector,
  ].forEach((formTypes, i) => {
    const getTrustFormTypes = [
      TrustFormTypes.Settlor,
      TrustFormTypes.Trustee,
      TrustFormTypes.Appointor,
      TrustFormTypes.UnitHolder,
      TrustFormTypes.Member,
      TrustFormTypes.SpecifiedBeneficiary,
      TrustFormTypes.Protector,
    ]
    formTypes.forEach((formData, index) => {
      const { entityId } = formData
      const formKey = getTrustFormTypes[i]

      const listOfLinkedEntityIds = getListOfLinkedEntityIds(analysis)

      // use the entityId if it exists, otherwise generate a new one
      let newId: string = entityId || generateNextNumber(listOfLinkedEntityIds)
      const dupIdsLength = docEntityIdsCopy.filter(x => x === entityId).length

      // check if the new entity should be created from the duplication of the same entity
      if (shouldCreateNewEntity(trust, formData)) {
        if (dupIdsLength > 1) {
          newId = generateNextNumber(listOfLinkedEntityIds)
          docEntityIdsCopy = removeDuplicatedItem(docEntityIdsCopy, entityId)
        } else {
          removeLinkedEntity(analysis, entityId)
        }
      }

      const commonForm = { formKey, formData, index }

      // Map common form types to linked entities & document information
      mapCommonLinkedData(commonForm, newId, documentInformation)
    })
  })

  // map general beneficiaries
  const generalBeneficiaries = form.beneficiary.generalBeneficiary.map(
    ({ value, referenceIds }) => ({
      referenceIds,
      value,
    }),
  )
  documentInformation.trust = mapDocumentInformationEntity({
    trustDocument: documentInformation.trust,
    formType: TrustKeyTypes.GeneralBeneficiary,
    typeInformationKey: TrustInformationTypes.discretionary,
    value: generalBeneficiaries,
  })

  // filter references object by document reference ids
  filterReferencesIds(analysis)

  // return the modified copy of analysis with the updated documentInformation
  return {
    references: analysis.references,
    documentInformation: analysis.documentInformation,
  }
}

export const checkAssociation = ({
  associatedParties,
  isIndividual,
  role,
  t,
  party,
}: CheckAssociationParam) => {
  const parties: TrustDeedAssociatedParty[] = []

  // check if its filtreable by role
  if (role) {
    parties.push(
      ...associatedParties.filter(associatedParty =>
        associatedParty.roles.some(
          associatedRole =>
            associatedRole.typeDescription.toLowerCase() ===
              role.toLowerCase() || associatedRole.typeDescription === t(role),
        ),
      ),
    )
  } else {
    parties.push(...associatedParties)
  }

  if (!isIndividual) {
    return parties
      .filter(
        associatedParty =>
          associatedParty.entityType === ApplicantEntityTypes.Organisation,
      )
      .some(
        ({ abnOrAcn, name }) =>
          abnOrAcn === party.abnOrAcn || name === party.name,
      )
  }

  return parties
    .filter(
      associatedParty =>
        associatedParty.entityType !== ApplicantEntityTypes.Organisation,
    )
    .some(
      associatedParty =>
        associatedParty.name.toLowerCase() === party.name?.toLowerCase(),
    )
}
