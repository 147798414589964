import React from 'react'

import { FrankieButton } from 'frankify/src'

import { useTableSelectionState } from 'features/profiles-table'

import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'

import {
  BULK_ASSIGN_WIDGET_KEY,
  bulkAssignWidgetEn,
} from '../../locale/bulk-assign.en'
import { BulkAssignOverlay } from '../bulk-assign-overlay/bulk-assign-overlay'

export function BulkAssign() {
  const { data } = useTableSelectionState()
  const [createOverlay] = useOverlay()
  const t = useI18n(BULK_ASSIGN_WIDGET_KEY, { keys: bulkAssignWidgetEn })

  const handleClick = () => {
    createOverlay(<BulkAssignOverlay />, {
      className: '!p-5 w-[440px]',
      closeButtonClassName: '!top-5 !right-5',
    })
  }

  return (
    <FrankieButton
      disabled={(data?.length ?? 0) === 0}
      className="px-5 py-2 disabled:text-tertiary-grey-500 disabled:!bg-mono-white hover:bg-tertiary-grey-200 bg-mono-white select-none cursor-pointer w-full text-start"
      onClick={handleClick}
      noStyles
    >
      {t('assignSelected')}
    </FrankieButton>
  )
}
