import { accountChangeEn, ACCOUNT_CHANGE_KEY } from 'features/account-change'
import { ACCOUNT_LOCKED_KEY, accountLockedEn } from 'features/account-locked'
import {
  ANALYTICS_DASHBOARD_KEY,
  analyticsDashboardEn,
} from 'features/analytics-dashboard'
import {
  APPLICANT_AUDIT_REPORT_KEY,
  applicantAuditReportEn,
} from 'features/applicant-audit-report'
import {
  APPLICANT_BIOMETRICS_OCR_KEY,
  applicantBiometricsOcrEn,
} from 'features/applicant-biometrics-ocr'
import {
  APPLICANT_BLOCKLISTED_INFO_KEY,
  applicantBlocklistedInfoEn,
} from 'features/applicant-blocklisted-info'
import {
  APPLICANT_BUSINESS_OWNERSHIP_KEY,
  applicantBusinessOwnershipEn,
} from 'features/applicant-business-ownership'
import {
  APPLICANT_BUSINESS_OWNERSHIP_INTERNATIONAL_KEY,
  applicantBusinessOwnershipInternationalEn,
} from 'features/applicant-business-ownership-international'
import {
  APPLICANT_FRAUD_CHECK_KEY,
  applicantFraudCheckEn,
} from 'features/applicant-fraud-check'
import {
  APPLICANT_GENERAL_INFORMATION_KEY,
  applicantGeneralInformationEn,
} from 'features/applicant-general-information'
import {
  APPLICANT_PEP_SACTIONS_KEY,
  applicantPepSactionsEn,
} from 'features/applicant-pep-sactions'
import {
  applicantPersonalInfoEn,
  APPLICANT_PERSONAL_INFO_KEYS,
} from 'features/applicant-personal-info'
import {
  applicantPotentialMatchesEn,
  APPLICANT_POTENTIAL_MATCHES_KEYS,
} from 'features/applicant-potential-matches'
import {
  APPLICANT_RISK_SCORE_KEY,
  applicantRiskScoreEn,
} from 'features/applicant-risk-score'
import {
  APPLICANT_SUPPORTING_DOCUMENTS_KEY,
  applicantSupportingDocumentsEn,
} from 'features/applicant-supporting-documents'
import {
  APPLICANT_TRANSACTIONS_KEY,
  applicantTransactionsEn,
} from 'features/applicant-transactions'
import {
  WORKFLOW_EVENTS_KEY,
  workflowEventsEn,
} from 'features/applicant-workflow-events'
import {
  APPLICANTS_FILTER_KEY,
  applicantsFilterEn,
} from 'features/applicants-filter'
import { BLOCKLIST_TABLE_KEY, blocklistTableEn } from 'features/blocklist-table'
import {
  BULK_ASSIGN_WIDGET_KEY,
  bulkAssignWidgetEn,
} from 'features/bulk-assign'
import { CUSTOM_VIEWS_KEYS, customViewEn } from 'features/custom-views'
import { DASHBOARD_KEY, dashboardEn } from 'features/dashboard'
import { EDIT_BUSINESS_KEY, editBusinessEn } from 'features/edit-business'
import { entitiesTableEn, ENTITIES_TABLE_KEY } from 'features/entities-table'
import {
  IKYB_AML_SCREENING_KEY,
  iKybAmlScreeningEn,
} from 'features/ikyb-aml-screening'
import {
  INDIVIDUAL_AML_RESULT_KEY,
  individualAmlResultEn,
} from 'features/individual-aml-result'
import {
  INDIVIDUAL_AML_SCREENING_KEY,
  individualAmlScreeningEn,
} from 'features/individual-aml-screening'
import {
  INDIVIDUAL_AUDIT_REPORT_F2_KEY,
  individualAuditReportF2En,
} from 'features/individual-audit-report-f2'
import {
  INDIVIDUAL_IDV_CHECK_KEY,
  individualIdvCheckEn,
} from 'features/individual-idv-check'
import {
  INDIVIDUAL_OCR_CHECK_KEY,
  individualOcrCheckEn,
} from 'features/individual-ocr-check'
import {
  INDIVIDUAL_PROFILE_KEY,
  individualProfileEn,
} from 'features/individual-profile'
import {
  individualProfileF2En,
  INDIVIDUAL_PROFILE_F2_KEY,
} from 'features/individual-profile-f2'
import {
  INDIVIDUAL_PROFILE_INSIGHT_KEY,
  individualProfileInsightEn,
} from 'features/individual-profile-insight'
import {
  INDIVIDUAL_SEND_VERIFICATION_LINK_KEY,
  individualSendVerificationLinkEn,
} from 'features/individual-send-verification-link'
import { KYB_SEARCH_V2_KEY, kybSearchV2En } from 'features/kyb-search-v2'
import { LOGIN_KEY, loginEn } from 'features/login'
import {
  monitoringOverviewEn,
  MONITORING_OVERVIEW_KEY,
} from 'features/monitoring-overview'
import { MFA_KEY, mfaEn } from 'features/multi-factor-auth'
import { NEW_PASSWORD_LOCALE, newPasswordEn } from 'features/new-password'
import {
  NOTIFICATIONS_MANAGEMENT_KEY,
  notificationsManagementEn,
} from 'features/notifications-management'
import {
  ORGANISATION_AUDIT_KEY,
  organisationAuditEn,
} from 'features/organisation-audit'
import {
  ORGANISATION_SEARCH_KEY,
  organisationSearchEn,
} from 'features/organisation-search'
import {
  ORGANISATION_SETTINGS_KEY,
  organisationSettingsEn,
} from 'features/organisation-settings'
import { PROFILES_FILTER_KEY, profilesFilterEn } from 'features/profiles-filter'
import { PROFILES_TABLE_KEY, profilesTableEn } from 'features/profiles-table'
import { roleManagementEn, ROLE_MANAGEMENT_KEY } from 'features/role-management'
import {
  ROLE_MANAGEMENT_F2_KEY,
  roleManagementF2En,
} from 'features/role-management-f2'
import { userManagementEn, USER_MANAGEMENT_KEY } from 'features/user-management'

export const features = {
  [NEW_PASSWORD_LOCALE]: newPasswordEn,
  [ROLE_MANAGEMENT_KEY]: roleManagementEn,
  [USER_MANAGEMENT_KEY]: userManagementEn,
  [NOTIFICATIONS_MANAGEMENT_KEY]: notificationsManagementEn,
  [LOGIN_KEY]: loginEn,
  [ACCOUNT_LOCKED_KEY]: accountLockedEn,
  [ORGANISATION_SETTINGS_KEY]: organisationSettingsEn,
  [ORGANISATION_SEARCH_KEY]: organisationSearchEn,
  [ORGANISATION_AUDIT_KEY]: organisationAuditEn,
  [MFA_KEY]: mfaEn,
  [ACCOUNT_CHANGE_KEY]: accountChangeEn,
  [MONITORING_OVERVIEW_KEY]: monitoringOverviewEn,
  [APPLICANTS_FILTER_KEY]: applicantsFilterEn,
  [PROFILES_FILTER_KEY]: profilesFilterEn,
  [ENTITIES_TABLE_KEY]: entitiesTableEn,
  [PROFILES_TABLE_KEY]: profilesTableEn,
  [DASHBOARD_KEY]: dashboardEn,
  [BLOCKLIST_TABLE_KEY]: blocklistTableEn,
  [INDIVIDUAL_PROFILE_KEY]: individualProfileEn,
  [APPLICANT_GENERAL_INFORMATION_KEY]: applicantGeneralInformationEn,
  [APPLICANT_SUPPORTING_DOCUMENTS_KEY]: applicantSupportingDocumentsEn,
  [APPLICANT_POTENTIAL_MATCHES_KEYS]: applicantPotentialMatchesEn,
  [APPLICANT_AUDIT_REPORT_KEY]: applicantAuditReportEn,
  [APPLICANT_PERSONAL_INFO_KEYS]: applicantPersonalInfoEn,
  [APPLICANT_TRANSACTIONS_KEY]: applicantTransactionsEn,
  [APPLICANT_RISK_SCORE_KEY]: applicantRiskScoreEn,
  [APPLICANT_BUSINESS_OWNERSHIP_KEY]: applicantBusinessOwnershipEn,
  [APPLICANT_BUSINESS_OWNERSHIP_INTERNATIONAL_KEY]:
    applicantBusinessOwnershipInternationalEn,
  [APPLICANT_BLOCKLISTED_INFO_KEY]: applicantBlocklistedInfoEn,
  [APPLICANT_BIOMETRICS_OCR_KEY]: applicantBiometricsOcrEn,
  [APPLICANT_FRAUD_CHECK_KEY]: applicantFraudCheckEn,
  [APPLICANT_PEP_SACTIONS_KEY]: applicantPepSactionsEn,
  [KYB_SEARCH_V2_KEY]: kybSearchV2En,
  [ROLE_MANAGEMENT_F2_KEY]: roleManagementF2En,
  [INDIVIDUAL_PROFILE_F2_KEY]: individualProfileF2En,
  [INDIVIDUAL_AUDIT_REPORT_F2_KEY]: individualAuditReportF2En,
  [WORKFLOW_EVENTS_KEY]: workflowEventsEn,
  [EDIT_BUSINESS_KEY]: editBusinessEn,
  [ANALYTICS_DASHBOARD_KEY]: analyticsDashboardEn,
  [INDIVIDUAL_PROFILE_INSIGHT_KEY]: individualProfileInsightEn,
  [IKYB_AML_SCREENING_KEY]: iKybAmlScreeningEn,
  [INDIVIDUAL_AML_SCREENING_KEY]: individualAmlScreeningEn,
  [INDIVIDUAL_AML_RESULT_KEY]: individualAmlResultEn,
  [INDIVIDUAL_IDV_CHECK_KEY]: individualIdvCheckEn,
  [INDIVIDUAL_OCR_CHECK_KEY]: individualOcrCheckEn,
  [CUSTOM_VIEWS_KEYS]: customViewEn,
  [INDIVIDUAL_SEND_VERIFICATION_LINK_KEY]: individualSendVerificationLinkEn,
  [BULK_ASSIGN_WIDGET_KEY]: bulkAssignWidgetEn,
}
