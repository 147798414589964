import React, { useEffect, useState } from 'react'

import { Document, DocumentProps, Page, PageProps } from 'react-pdf'

type Props = {
  url: string
  documentProps?: DocumentProps
  pageProps?: PageProps
  focusPageNumber?: number
}

export function PdfPreview({
  url,
  documentProps,
  pageProps,
  focusPageNumber,
}: Props) {
  const [numPages, setNumPages] = useState<number>()

  useEffect(() => {
    if (focusPageNumber && numPages) {
      const pageNumber = Math.min(numPages, focusPageNumber)
      const page = document.querySelector(
        `.react-pdf__Page[data-page-number="${pageNumber}"]`,
      )
      page?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [focusPageNumber, numPages])

  return (
    <Document
      file={url}
      onLoadSuccess={({ numPages }) => setNumPages(numPages)}
      className="grid bg-tertiary-grey-100 gap-4"
      {...documentProps}
    >
      {Array.from(new Array(numPages), (_, index) => (
        <Page
          key={`page_${index + 1}`}
          pageNumber={index + 1}
          width={pageProps?.width}
          {...pageProps}
        />
      ))}
    </Document>
  )
}
