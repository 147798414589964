import React from 'react'

import { UseFormReturn } from 'react-hook-form'

import { countryPhoneCodeList } from 'entities/country'

import { PhoneFormField, TextFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'
import { EMAIL_PATTERN, useValidationRules } from 'shared/validation'

import { INDIVIDUAL_PROFILE_F2_KEY } from '../../individual-profile-f2.key'
import { individualProfileF2En } from '../../locale/individual-profile-f2.en'
import { IIndividualProfileF2FormType } from '../../model/individual-profile-f2-form.model'
import { personalInfoFormF2Qa } from '../../qa/individual-profile-f2.qa'

export type Props = {
  form: UseFormReturn<IIndividualProfileF2FormType>
  showOptionalFields?: boolean
}

export function PersonalInfo({ form, showOptionalFields = true }: Props) {
  const { control } = form

  const { nonNumericRule, numericRule } = useValidationRules()
  const t = useI18n([INDIVIDUAL_PROFILE_F2_KEY], {
    keys: individualProfileF2En,
  })

  return (
    <div data-qa={personalInfoFormF2Qa.container} className="">
      <div
        className="text-lg  text-tertiary-grey-800 mb-[14px] font-bold"
        data-qa={personalInfoFormF2Qa.title}
      >
        {t('personalInfo')}
      </div>

      <div className="grid grid-cols-3 gap-[20px]">
        <TextFormField
          className=""
          trim
          label={`${t('profileForm.customerRef')} ${t('optional')}`}
          control={control}
          name="customerId"
          testId={{ input: personalInfoFormF2Qa.customerId }}
        />

        {/* To maintain the grid structure */}
        <div className="col-span-2"> </div>

        <TextFormField
          label={`${t('profileForm.firstName')}`}
          control={control}
          name="name.givenName"
          trim
          rules={{
            required: t('profileForm.errors.firstName'),
            ...nonNumericRule,
          }}
          showErrorText
          testId={{ input: personalInfoFormF2Qa.firstName }}
        />

        <TextFormField
          trim
          label={`${t('profileForm.middleName')} ${t('optional')}`}
          control={control}
          name="name.middleName"
          rules={nonNumericRule}
          showErrorText
          testId={{ input: personalInfoFormF2Qa.middleName }}
        />

        <TextFormField
          label={`${t('profileForm.lastName')}`}
          control={control}
          trim
          name="name.familyName"
          rules={{
            required: t('profileForm.errors.lastName'),
            ...nonNumericRule,
          }}
          showErrorText
          testId={{ input: personalInfoFormF2Qa.lastName }}
        />

        <TextFormField
          type="date"
          label={`${t('profileForm.dob')}`}
          control={control}
          name="dateOfBirth.normalized"
          placeholder={t('profileForm.dobPlaceholder')}
          rules={{
            required: t('profileForm.errors.dob'),
            validate: value =>
              new Date(`${value as string}`).valueOf() < Date.now() ||
              t('profileForm.errors.validDob'),
          }}
          showErrorText
          testId={{ input: personalInfoFormF2Qa.dateOfBirth }}
        />

        {showOptionalFields && (
          <>
            {/* To maintain the grid structure */}
            <div className="col-span-2"> </div>

            <PhoneFormField
              label={`${t('profileForm.phoneNumber')} ${t('optional')}`}
              control={control}
              name="phoneNumbers.0.number"
              countryList={countryPhoneCodeList.map(country => ({
                label: `${country.alpha2} +${country.value}`,
                value: country.code,
                alpha2code: country.alpha2,
              }))}
              countryName="phoneNumbers.0.country"
              placeholder={t('profileForm.phoneNumberPlaceholder')}
              rules={numericRule}
              showErrorText
              testId={{ input: personalInfoFormF2Qa.phoneNumber }}
            />

            <TextFormField
              className=" col-span-2"
              label={`${t('profileForm.email')} ${t('optional')}`}
              placeholder={t('profileForm.emailPlaceholder')}
              control={control}
              name="emailAddresses.0.email"
              rules={{
                pattern: {
                  value: EMAIL_PATTERN,
                  message: t('profileForm.errors.email'),
                },
              }}
              showErrorText
              testId={{ input: personalInfoFormF2Qa.email }}
            />
          </>
        )}
      </div>
    </div>
  )
}
