import React, { useState } from 'react'

import { Document, DocumentProps, Page, PageProps } from 'react-pdf'

type Props = {
  url: string
  documentProps?: DocumentProps
  pageProps?: PageProps
}

export function PdfPreview(props: Props) {
  const [numPages, setNumPages] = useState<number>()

  return (
    <Document
      file={props.url}
      onLoadSuccess={({ numPages }) => setNumPages(numPages)}
      className="grid bg-tertiary-grey-100 gap-4"
      {...props.documentProps}
    >
      {Array.from(new Array(numPages), (_, index) => (
        <Page
          key={`page_${index + 1}`}
          pageNumber={index + 1}
          {...props.pageProps}
        />
      ))}
    </Document>
  )
}
