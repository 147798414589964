import React from 'react'

import { FrankieAvatar } from 'frankify/src'

import { DateFormatTypes, formatDate } from 'shared/date-time'

import { CommentsTypes } from '../../model/comments.model'
import { commentBoxQa } from '../../qa/comments.qa'
import { useCommentData } from '../../states/comments-states/comments.state'

export function CommentBox({
  entityId,
  processId,
  commentType,
}: {
  entityId: string
  processId?: string
  commentType: CommentsTypes
}) {
  const { data } = useCommentData({ commentType, entityId, processId })

  if (!data.length) return null
  return (
    <div
      className="bg-tertiary-grey-200 rounded-sm flex flex-col gap-2"
      data-qa={commentBoxQa.container}
    >
      <div className="flex gap-2 items-center">
        <FrankieAvatar
          name={data[0].source ?? ''}
          size="md"
          testId={{ initial: commentBoxQa.avatar }}
        />
        <div className="flex flex-col gap-1">
          <div
            className="text-tertiary-grey-800 text-sm font-semibold leading-3 truncate"
            data-qa={commentBoxQa.name}
          >
            {data[0].source ?? ''}
          </div>
          <div
            className="text-tertiary-grey-500 leading-4 text-xs"
            data-qa={commentBoxQa.date}
          >
            {formatDate(data[0].timestamp || '', DateFormatTypes.ShortDate)}{' '}
          </div>
        </div>
      </div>
      <div
        className="text-tertiary-grey-700 font-bold text-sm leading-4 max-h-9 line-clamp-2 overflow-hidden"
        data-qa={commentBoxQa.action}
      >
        {data[0].actionTaken}{' '}
        {data[0].status && (
          <span
            data-qa={commentBoxQa.status}
            className={`${
              data[0].status.textColor ?? 'text-tertiary-grey-700'
            } font-bold text-sm leading-4`}
          >
            {data[0].status.text}
          </span>
        )}
      </div>
      <div
        className="text-tertiary-grey-600 text-xs whitespace-break-spaces max-h-[90px] line-clamp-6 overflow-hidden"
        data-qa={commentBoxQa.comment}
      >
        {data[0].userComment?.text}
      </div>
    </div>
  )
}
