import React, { useEffect } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import { FrankieButton, FrankieIcon, FrankieLoader } from 'frankify/src'

import { IndividualAmlScreening } from 'features/individual-aml-screening'

import { ApplicantId } from 'entities/applicant'
import { useEntityDataQuery, useFrankie2R2Customer } from 'entities/entity'
import { useApplicantRoute } from 'entities/routing'

import { useI18n } from 'shared/i18n'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { INDIVIDUAL_AML_HISTORY_KEY } from '../individual-aml-history.key'
import { individualAmlHistoryEn } from '../locale/individual-aml-history.en'

type Props = {
  applicantIdParamKey: ApplicantId
}

export function IndividualAmlHistoryPage({ applicantIdParamKey }: Props) {
  const t = useI18n(INDIVIDUAL_AML_HISTORY_KEY, {
    keys: individualAmlHistoryEn,
  })

  const params = useParams()
  const paramsEntityId = params[applicantIdParamKey]
  const { data, isLoading } = useEntityDataQuery(paramsEntityId)

  const isFrankie2 = useFrankie2R2Customer()

  const navigate = useNavigate()

  useEffect(() => {
    trackingManager.track(TrackingEventsTypes.AmlHistoryPageShowIndividual)
  }, [])

  const handleBack = () => {
    navigate(-1)
  }
  const { generateRoute } = useApplicantRoute()

  if (!paramsEntityId || !isFrankie2) return null

  if (isLoading) {
    return <FrankieLoader fullscreen loading />
  }

  return (
    <div className=" z-0 relative max-h-[calc(100vh-70px)] overflow-y-auto scrollbar  ">
      <div className=" text-tertiary-grey-700 px-8 mb-28 mx-auto h-full">
        <div className="w-full  mx-auto sticky bg-mono-white top-0 flex flex-col justify-items-start item-center pt-6 pb-4 z-[100]">
          <FrankieButton
            noStyles
            startIcon={{
              name: 'mdiArrowLeft',
              size: 'xs',
              className: 'text-tertiary-grey-500',
            }}
            onClick={handleBack}
            className="inline-flex gap-1"
          >
            <span className="text-xs text-tertiary-grey-500">{t('back')}</span>
          </FrankieButton>
          <div className="mt-4 flex gap-1 items-center">
            <FrankieButton
              noStyles
              data-hj-suppress
              className=" text-xs text-tertiary-grey-600"
              onClick={() =>
                navigate(
                  generateRoute({
                    routeKey: 'applicantProfileWorkflowEvent',
                    overrideParams: { applicantId: paramsEntityId },
                  }),
                )
              }
            >
              {data?.individual?.name?.displayName}
            </FrankieButton>
            <FrankieIcon
              className="text-tertiary-grey-400"
              name="mdiChevronRight"
              size="xs"
            />
            <FrankieButton
              noStyles
              className="text-xs text-tertiary-grey-700"
              onClick={() =>
                navigate(
                  `${generateRoute({
                    routeKey: 'applicantProfileWorkflowEvent',
                    overrideParams: { applicantId: paramsEntityId },
                  })}#AML`,
                )
              }
            >
              {data?.serviceProfiles?.at(0)?.currentWorkflowName}
            </FrankieButton>
            <FrankieIcon
              className="text-tertiary-grey-400"
              name="mdiChevronRight"
              size="xs"
            />
            <div className="text-xs text-tertiary-grey-700">{t('title')} </div>
          </div>
          <div className="pt-2 text-2xl font-bold text-tertiary-grey-800 leading-tight">
            {t('title')}
          </div>
          <div className="text-tertiary-grey-500 text-sm mt-1">
            {t('description')}
          </div>
        </div>

        <IndividualAmlScreening entityId={paramsEntityId} historyPage />
      </div>
    </div>
  )
}
