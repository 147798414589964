export const NOTIFICATIONS_MANAGEMENT_KEY = 'notifications-management'

export const notificationsManagementEn = {
  type: {
    header: 'Entity notifications',
    frequency: 'Frequency',
    types: {
      entityAssignment: {
        name: 'Entity assignment',
        description: 'Notify me when an entity is assigned to me',
      },
      entityStatusUpdatedToNeedsAttentionFailedManuallyFailed: {
        name: 'Workflow status',
        description:
          'Notify me when the workflow status of entities is updated to Needs attention, Failed, or Manually failed ',
      },
      assignedEntityStatusUpdatedToNeedsAttentionFailedManuallyFailed: {
        name: 'Entity status',
        description:
          'Notify me when the entity status of entities is updated to Needs attention, Failed, or Manually failed ',
      },
      entityRiskUpdatedToHighUnacceptable: {
        name: 'Risk level updates on any entities',
        description:
          'Notify me when the risk level of any entities is updated to High or Unacceptable',
      },
      assignedEntityRiskUpdatedToHighUnacceptable: {
        name: 'Risk level updates on entities assigned to me',
        description:
          'Notify me when the risk level of entities assigned to me is updated to High or Unacceptable',
      },
      trustAnalysisComplete: {
        name: 'Trust analyser',
        description:
          'Notify me when the Trust Analyser completes its evaluation of trust deeds and when the trust analysis is available for entities that are assigned to me',
      },
    },
    frequencyOptions: {
      realTime: 'Real-time',
      daily: 'Daily',
      weekly: 'Weekly',
    },
  },
  general: {
    header: 'General notification',
    frequency: 'Frequency',
    types: {
      entityAssignment: {
        name: 'Entity updates',
        description:
          'Notify me for any updates from all entities or only those that are assigned to me',
      },
    },
    frequencyOptions: {
      allEntities: 'All entities',
      onlyAssignedEntities: 'Assigned entities',
    },
  },
  channel: {
    PORTAL: 'PORTAL',
    EMAIL: 'EMAIL',
  },
}
