import React, { useEffect, useMemo } from 'react'

import { useInView } from 'react-intersection-observer'
import { useSearchParams } from 'react-router-dom'

import { FrankieLoader } from 'frankify/src'

import { ApplicantId } from 'entities/applicant'
import {
  AuditReportSourceOptionsTypes,
  StatusTypes,
  useAuditReportData,
} from 'entities/individual-audit-report-f2'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { TrackingEventsTypes, useTrackingShowEvents } from 'shared/tracking'

import {
  EventsType,
  AuditReportRecord,
  getTimeDifference,
} from '../../model/individual-audit-report-f2.model'
import { individualReportSectionF2Qa } from '../../qa/individual-audit-report-f2.qa'
import {
  CheckStatusMapper,
  EmptyView,
  IndividualReportSectionPage,
  WorkflowStatusMapper,
} from '../individual-audit-report-helper-f2/individual-audit-report-helper-f2'
import { IndividualReportCard } from '../individual-report-card/individual-report-card'

type SectionTimelineProps = {
  currentData: AuditReportRecord
  event: EventsType
  idx: number
  previousData: EventsType[]
  eventTimelineIdx: number
  data: AuditReportRecord[]
  nextPage?: number
  date: string
}

function SectionTimeline({
  currentData,
  event,
  idx,
  previousData,
  eventTimelineIdx,
  data,
  nextPage,
  date,
}: Readonly<SectionTimelineProps>) {
  const centerClass = 'flex flex-col items-center mr-6 justify-center'

  const isLastTimeline = useMemo(
    () =>
      idx === previousData.length - 1 &&
      eventTimelineIdx === data.length - 1 &&
      nextPage === undefined,
    [idx, previousData.length, eventTimelineIdx, data, nextPage],
  )

  return (
    <div className="flex justify-between gap-3 w-full" key={event.time}>
      <div className="flex flex-col w-[120px]">
        <div
          className="h-11 relative"
          data-qa={individualReportSectionF2Qa.statusWrapper}
        >
          {event.eventType === 'check' ? (
            <CheckStatusMapper type={event.entityStatus} />
          ) : (
            <WorkflowStatusMapper type={event.entityStatus} />
          )}
        </div>
        <p
          className={`bg-mono-white py-2 pl-3 text-sm text-tertiary-grey-800 leading-[18px] ${
            event.entityStatus !== StatusTypes.UNCHECKED
              ? 'mt-[19px]'
              : 'mt-[9px]'
          } ${isLastTimeline ? 'flex-1' : ''}`}
          data-qa={individualReportSectionF2Qa.time}
        >
          {formatDate(
            `${date} ${event.time}`,
            DateFormatTypes.TimeWithSeconds,
          ).toUpperCase()}
        </p>

        {idx < previousData.length - 1 && (
          <div className={`${centerClass} mt-2`}>
            <p className="text-tertiary-grey-400 bg-mono-white text-center">
              {getTimeDifference({
                time1: `${currentData.date} ${event.time}`,
                time2: `${currentData.date} ${previousData[idx + 1].time}`,
              })}
            </p>
          </div>
        )}
      </div>
      <IndividualReportCard eventData={event} idx={idx} />
    </div>
  )
}

type Props = {
  applicantId: ApplicantId
}

export function IndividualReportSection({ applicantId }: Readonly<Props>) {
  const [searchParams] = useSearchParams()

  const mock = searchParams.get('mock') === 'true'

  const { pages, fetchNextPage, isFetchingNextPage, status, hasNextPage } =
    useAuditReportData({
      filters: { type: AuditReportSourceOptionsTypes.ENTITY },
      entityId: applicantId,
      mock,
    })

  useTrackingShowEvents({
    data: pages,
    eventsType: TrackingEventsTypes.IndividualAuditReportView,
  })

  const { ref, inView } = useInView()

  useEffect(() => {
    if (inView && !isFetchingNextPage) {
      if (hasNextPage) {
        void fetchNextPage()
      }
    }
  }, [inView, fetchNextPage, isFetchingNextPage, hasNextPage])

  if (status === 'loading')
    return (
      <div className="mt-6 w-full relative ">
        <FrankieLoader loading className="!pt-24" />
      </div>
    )

  if (!pages?.[0]?.data?.length) return <EmptyView />

  return (
    <div
      className="mt-6 w-full relative z-0"
      data-qa={individualReportSectionF2Qa.header}
    >
      <div
        className="absolute border border-tertiary-grey-200 h-full w-[1px] mb-1 left-[44px] -z-10"
        data-qa={individualReportSectionF2Qa.line}
      />

      {pages.map((page, pageId) => (
        <IndividualReportSectionPage currentPage={page.currentPage}>
          {page.data?.map((data: AuditReportRecord, ind) => (
            <React.Fragment
              key={`${data.date}-${pageId.toString()}-${ind.toString()}`}
            >
              <div className="w-24 mt-7 bg-mono-white py-1">
                <p
                  className="font-bold text-tertiary-grey-800 bg-mono-white text-sm leading-3 text-center"
                  data-qa={individualReportSectionF2Qa.date}
                >
                  {data.date}
                </p>
              </div>
              {data.events.map((event, idx, self) => (
                <SectionTimeline
                  key={`${
                    event.time
                  }-${pageId.toString()}-${ind.toString()}-${idx.toString()}`}
                  currentData={data}
                  event={event}
                  idx={idx}
                  previousData={self}
                  eventTimelineIdx={ind}
                  data={page.data as AuditReportRecord[]}
                  nextPage={page.nextPage}
                  date={data.date}
                />
              ))}
            </React.Fragment>
          ))}
        </IndividualReportSectionPage>
      ))}

      <div className="h-3 bg-mono-white" ref={ref} />
      {isFetchingNextPage && (
        <FrankieLoader loading={isFetchingNextPage} className="!pt-24" />
      )}
    </div>
  )
}
