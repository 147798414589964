/* eslint-disable complexity */
import React, { ReactNode, useEffect, useState } from 'react'

import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'

import { FrankieAvatar, FrankieButton, FrankieDivider } from 'frankify/src'

import { COMMENT_KEY } from 'entities/comments/comments.key'
import { commentsEn } from 'entities/comments/locale/comments.en'
import { expandedCommentQa } from 'entities/comments/qa/comments.qa'
import { useApplicantRoute, useGlobalAppState } from 'entities/routing'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { useI18n } from 'shared/i18n'
import { Nullable } from 'shared/typescript'

import { CommentsTypes, SupplementaryData } from '../../model/comments.model'
import {
  useCommentBoxVisibility,
  useCommentData,
  useUpdateCommentBoxVisibility,
} from '../../states/comments-states/comments.state'

const ScrollableCommentSection = [CommentsTypes.WORKFLOW, CommentsTypes.AML]

type ExpandedCommentBoxProps = {
  commentType: CommentsTypes
  entityId: string
  processId?: string
}

export function ExpandedCommentBox({
  commentType,
  entityId,
  processId,
}: ExpandedCommentBoxProps) {
  const { data } = useCommentData({
    entityId,
    commentType,
    processId,
  })

  const [expanded, setExpanded] = useState<boolean[]>(data.map(() => false))
  const t = useI18n([COMMENT_KEY], { keys: commentsEn })
  const { data: isVisible } = useCommentBoxVisibility({ entityId })
  const { data: amlData } = useCommentData({
    entityId,
    commentType: CommentsTypes.AML,
    processId,
  })
  const maxCollapsedHeight = 150
  const maxExpandedChars = 500

  const toggleExpand = (index: number) => {
    setExpanded(prev => {
      const newExpanded = [...prev]
      newExpanded[index] = !newExpanded[index]
      return newExpanded
    })
  }

  const handleScroll = (value: CommentsTypes) => {
    const scrollCommentIndex = ScrollableCommentSection.findIndex(
      item => item === value,
    )
    const nextIndex =
      scrollCommentIndex + 1 === ScrollableCommentSection.length
        ? 0
        : scrollCommentIndex + 1
    const nextCom = document.getElementById(ScrollableCommentSection[nextIndex])
    nextCom?.scrollIntoView({ behavior: 'smooth' })
  }

  const isIconDisable =
    isVisible &&
    isVisible[CommentsTypes.WORKFLOW] &&
    isVisible[CommentsTypes.AML] &&
    amlData.length

  const { updateAppState } = useGlobalAppState()

  useEffect(() => {
    if (isVisible) updateAppState({ isSidebarOpen: false })
  }, [isVisible])

  const updateComment = useUpdateCommentBoxVisibility({ entityId })
  const { generateRoute } = useApplicantRoute()
  const navigate = useNavigate()

  const updateCommentBoxVisibility = useUpdateCommentBoxVisibility({
    entityId,
  })

  if (!data.length) return null

  return (
    <div
      className="w-full min-w-[220px] max-w-[270px] h-max max-h-[700px] overflow-auto bg-tertiary-grey-100 pb-4 pl-3 pr-4 rounded-sm relative"
      id={commentType}
      data-qa={expandedCommentQa.container}
    >
      <div className="flex justify-between items-center mb-4 sticky z-10 bg-tertiary-grey-100 top-0 pt-3">
        <div className="flex justify-between">
          <div
            className="text-tertiary-grey-800 font-bold text-md leading-6 max-w-[152px] min-w-[100px] truncate"
            data-qa={expandedCommentQa.title}
          >
            {t(`title.${commentType}`)}
          </div>
          {!isIconDisable ||
            (commentType !== CommentsTypes.PROFILE && (
              <div className="w-[56px]">
                <FrankieButton
                  noStyles
                  singleIcon={{
                    name: 'mdiChevronUp',
                    className: 'text-tertiary-grey-700',
                    size: 'xs',
                  }}
                  className="w-1/2 h-full"
                  onClick={() => handleScroll(commentType)}
                  testId={{ button: expandedCommentQa.upIcon }}
                />
                <FrankieButton
                  noStyles
                  singleIcon={{
                    name: 'mdiChevronDown',
                    className: 'text-tertiary-grey-700',
                    size: 'xs',
                  }}
                  className="w-1/2 h-full"
                  onClick={() => handleScroll(commentType)}
                  testId={{ button: expandedCommentQa.downIcon }}
                />
              </div>
            ))}
        </div>
        <FrankieButton
          noStyles
          singleIcon={{
            name: 'mdiClose',
            size: 'xs',
            className: 'text-tertiary-grey-400',
          }}
          onClick={() =>
            updateComment({
              payload: commentType,
            })
          }
          testId={{ button: expandedCommentQa.closeIcon }}
        />
      </div>

      {data.map((commentData, index) => {
        const isExpanded = expanded[index]
        // eslint-disable-next-line no-nested-ternary
        const messageToShow = isExpanded
          ? commentData.userComment?.text ?? ''.substring(0, maxExpandedChars)
          : (commentData.userComment?.text ?? '').length > maxCollapsedHeight
          ? `${(commentData.userComment?.text ?? '').substring(
              0,
              maxCollapsedHeight,
            )}...`
          : commentData.userComment?.text

        return (
          <div
            key={commentData.batchId}
            className="flex flex-col gap-4 grow-0"
            data-qa={expandedCommentQa.commentContainer}
          >
            <div className="flex gap-2 items-center">
              <FrankieAvatar
                name={commentData.source ?? ''}
                size="md"
                testId={{ initial: expandedCommentQa.avatar }}
                className="min-w-[32px] min-h-[32px]"
              />
              <div className="flex flex-col gap-1">
                <div
                  className="text-tertiary-grey-800 text-sm font-semibold leading-3 truncate"
                  data-qa={expandedCommentQa.name}
                >
                  {commentData.source ?? ''}
                </div>
                <div
                  className="text-tertiary-grey-500 leading-4 text-xs"
                  data-qa={expandedCommentQa.date}
                >
                  {formatDate(commentData.timestamp, DateFormatTypes.ShortDate)}{' '}
                  {formatDate(commentData.timestamp, DateFormatTypes.Time)}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div
                className="text-tertiary-grey-700 font-semibold text-sm leading-4"
                data-qa={expandedCommentQa.action}
              >
                {commentData.actionTaken}
                {commentData.status && (
                  <span
                    data-qa={expandedCommentQa.status}
                    className={`${
                      commentData.status.textColor ?? 'text-inherit'
                    }  text-sm font-semibold leading-4`}
                  >
                    {commentData.status.text ?? ''}
                  </span>
                )}
              </div>
              <div
                className="text-tertiary-grey-600 text-sm whitespace-break-spaces break-words"
                data-qa={expandedCommentQa.comment}
              >
                {messageToShow}
              </div>
              {(commentData.userComment?.text ?? '').length >
                maxCollapsedHeight && (
                <FrankieButton
                  noStyles
                  onClick={() => toggleExpand(index)}
                  className="text-primary-800 text-sm -mt-1 text-start"
                >
                  {isExpanded ? t('seeLess') : t('seeMore')}
                </FrankieButton>
              )}
              {(commentType === CommentsTypes.AML ||
                commentType === CommentsTypes.AMLRESULT) &&
                (
                  (commentData.supplementaryData as Nullable<SupplementaryData>)
                    ?.resultsUpdated ?? []
                ).length > 1 && (
                  <FrankieButton
                    noStyles
                    onClick={() => {
                      if (commentType === CommentsTypes.AMLRESULT) {
                        updateCommentBoxVisibility({
                          payload: CommentsTypes.AMLRESULT,
                        })
                        navigate(
                          generateRoute({
                            routeKey: 'applicantProfileWorkflowEvent',
                            overrideParams: { entityId },
                          }),
                          {
                            state: '#AML',
                          },
                        )
                      }
                    }}
                    className="text-primary-800 text-sm text-start"
                  >
                    {t('viewBatch')}
                  </FrankieButton>
                )}
            </div>
            {index !== data.length - 1 && <FrankieDivider className="!pb-4" />}
          </div>
        )
      })}
    </div>
  )
}

export function CommentHOC({
  children,
  className = '',
  entityId,
  commentType,
  processId,
}: {
  children: ReactNode
  className?: string
  entityId: string
  commentType: CommentsTypes
  processId?: string
}) {
  const { data } = useCommentBoxVisibility({ entityId })
  const { data: commentData } = useCommentData({
    entityId,
    commentType,
    processId,
  })

  return (
    <div className={`flex gap-4 grow-0 justify-between ${className}`}>
      <div
        className={classNames('w-full', {
          'min-w-[calc(100%-216px)] desktop:min-w-[calc(100%-282px)] max-w-[calc(100%-266px)]':
            data?.[commentType] && commentData.length,
        })}
      >
        {' '}
        {children}
      </div>

      {data?.[commentType] && (
        <ExpandedCommentBox
          commentType={commentType}
          entityId={entityId}
          processId={processId}
        />
      )}
    </div>
  )
}
