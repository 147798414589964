import { SupplementaryDataAML, useEntityAmlData } from 'entities/entity'

type Args = {
  entityId: string
  processResultId?: string
}
export const useAmlData = ({ entityId, processResultId }: Args) => {
  const { data } = useEntityAmlData({ entityId })
  const amlData = data.processResults?.find(
    processResult => processResult.processResultId === processResultId,
  )

  const supplementaryData = amlData?.supplementaryData as
    | SupplementaryDataAML
    | undefined
  return { amlData: { ...amlData, supplementaryData } }
}
